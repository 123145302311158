import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Corso, Video } from './../models/video'
import { Observable } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class VideoService {

  private readonly url = 'https://pcprecisioncoaching.it/api';

  constructor(private http: HttpClient) { }

  getVideos(): Observable<Video[]> {
    return this.http.get<Video[]>(this.url + '/video_tags');
  }

  getCorsi(): Observable<Corso[]> {
    return this.http.get<Corso[]>(this.url + '/video_tags');
  }
}
