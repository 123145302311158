<!--accordion1 section start-->

<section class="saas1 faq testimonial-bg">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class="title title2 title-inner">
                    <div class="main-title">
                        <h2 class="font-primary borders text-center main-text m-b-0"><span
                                class="text-uppercase">FAQ</span>
                        </h2>
                    </div>

                </div>
            </div>
            <div class="row">
                <div class="faq-block">
                    <div class="w-100">
                        <ngb-accordion [closeOthers]="true" class="accordion faq" activeIds="static-1">
                            <ngb-panel id="static-1" class="card">
                                <ng-template ngbPanelTitle class="card-header">
                                    <div class="collapsed" data-toggle="collapse" data-target="#collapse1"
                                        aria-expanded="false">
                                        Come funziona la prima visita di coaching (allenamento + alimentazione)?
                                    </div>
                                </ng-template>
                                <ng-template ngbPanelContent class="card-body">
                                    La prima visita di coaching viene effettuata presso la mia sede operativa , presso
                                    Francis Lab
                                    Via Malta 4E. Ha la durata variabile di 90/120 minuti. Questo lasso di tempo mi
                                    permetterà una completa anamnesi alimentare, la raccolta dei dati sulla composizione
                                    corporea del
                                    soggetto, la definizione degli obiettivi specifici, la parte di allenamento vera e
                                    propria, con visione
                                    e correzione degli esercizi che verranno proposti in scheda.
                                </ng-template>
                            </ngb-panel>
                            <ngb-panel id="static-2" class="card">
                                <ng-template ngbPanelTitle class="card-header">
                                    <div class="collapsed" data-toggle="collapse" data-target="#collapse1"
                                        aria-expanded="false">
                                        Come funziona la prima visita nutrizionale?
                                    </div>
                                </ng-template>

                                <ng-template ngbPanelContent class="card-body">
                                    La prima visita di nutrizionale viene effettuata presso una delle mie sedi operative
                                    , Studio Aura
                                    Soc. Coop presso Strada Gragnana oppure Studio Aequilibrium Tennuoto via.... Ha la
                                    durata
                                    variabile di 60/75 minuti. Questo lasso di tempo mi permetterà una completa anamnesi
                                    alimentare,
                                    la raccolta dei dati sulla composizione corporea del soggetto tramite plicometria,
                                    la definizione
                                    degli obiettivi specifici assieme al paziente e la prenotazione della successiva
                                    visita di controllo.
                                    Successivamente, il piano alimentare personalizzato ed i relativi dati sull’analisi
                                    corporea,
                                    verranno inviati al paziente tramite mail.
                                </ng-template>
                            </ngb-panel>
                            <ngb-panel id="static-3" class="card">
                                <ng-template ngbPanelTitle class="card-header">
                                    <div class="collapsed" data-toggle="collapse" data-target="#collapse1"
                                        aria-expanded="false">
                                        Quali sono i costi dei tuoi servizi?
                                    </div>
                                </ng-template>

                                <ng-template ngbPanelContent class="card-body">
                                    Compila il modulo -> https://www.precisioncoaching.info/, e riceverai una mail
                                    esplicativa con
                                    tutti i prezzi.
                                </ng-template>
                            </ngb-panel>
                            <ngb-panel id="static-4" class="card">
                                <ng-template ngbPanelTitle class="card-header">
                                    <div class="collapsed" data-toggle="collapse" data-target="#collapse1"
                                        aria-expanded="false">
                                        Come funziona il coaching online?
                                    </div>
                                </ng-template>

                                <ng-template ngbPanelContent class="card-body">
                                    Una volta compilato il modulo -> https://www.precisioncoaching.info/ , riceverai una
                                    mail
                                    esplicativa con tutti i prezzi dei miei servizi ed un link per prenotare una prima
                                    chiamata
                                    conoscitiva con me. ATTENZIONE, il servizio online richiede monitoraggio e feedback
                                    da parte
                                    della persona, per mantenere una qualità del servizio.
                                </ng-template>
                            </ngb-panel>



                            <ngb-panel id="static-5" class="card">
                                <ng-template ngbPanelTitle class="card-header">
                                    <div class="collapsed" data-toggle="collapse" data-target="#collapse1"
                                        aria-expanded="false">
                                        Segui anche atleti professionisti?
                                    </div>
                                </ng-template>

                                <ng-template ngbPanelContent class="card-body">
                                    Assolutamente si. Possiedo la massima certificazione per la nutrizione ed
                                    integrazione nello sport in
                                    Italia (sanis (https://www.sanis.it/)). Seguo tuttora atleti di diverse discipline →
                                    alla voce i miei atleti
                                </ng-template>
                            </ngb-panel>
                            <ngb-panel id="static-6" class="card">
                                <ng-template ngbPanelTitle class="card-header">
                                    <div class="collapsed" data-toggle="collapse" data-target="#collapse1"
                                        aria-expanded="false">
                                        Fai sedute di personal training 1to1?
                                    </div>
                                </ng-template>

                                <ng-template ngbPanelContent class="card-body">
                                    Si, presso la mia sede operativa Francis Lab Via Malta 4E 25127 Brescia
                                </ng-template>
                            </ngb-panel>
                        </ngb-accordion>
                    </div>
                </div>
            </div>

        </div>
    </div>
</section>

<!--accordion1 section end-->