import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { VideoService } from '../../services/video.service';
import { Corso } from 'src/app/models/video';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-corsi',
  templateUrl: './corsi.component.html',
  styleUrls: ['./corsi.component.scss']
})
export class CorsiComponent implements OnInit {
  load: boolean = false;
  videos: any[] = [];
  description = '';
  searchText = '';
  videoDaVisualizzare: string | null = null; // Inizializzato a null
  title: string = '';
  corsi: Corso[] = [];
  isCollapsed: boolean[] = [];
  isMenuCollapsed: boolean = true;

  constructor(private readonly videoService: VideoService, public sanitizer: DomSanitizer) {}

  onVideoSelect(video: any) {
    this.videoDaVisualizzare = video.player_embed_url;
    this.title = video.name;
    this.description = video.description;
  }

  handleTime(sec: number): string {
    var date = new Date(null);
    date.setSeconds(sec);
    return date.toISOString().substr(14, 5);
  }

  ngOnInit(): void {
    Swal.fire({
      title: 'Caricamento in corso...',
      showConfirmButton: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
    this.videoService.getCorsi().subscribe(
      (corsi) => this.handleMenu(corsi),
      (error) => {
        console.error(error);
      }
    );
  }

  private handleMenu(corsi: Corso[]): void {
    this.corsi = corsi;
    this.isCollapsed = new Array(this.corsi.length).fill(false);
    this.isCollapsed[0] = true; // Espande la prima sezione di default

    if (this.corsi.length > 0 && this.corsi[1].video && this.corsi[1].video.length > 0) {
      const primoVideo = this.corsi[1].video[1];
      this.onVideoSelect(primoVideo);
    }

    Swal.close();
  }

  toggleMenu() {
    this.isMenuCollapsed = !this.isMenuCollapsed;
  }
}
