import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { UserService } from 'src/app/services/user.service';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.scss']
})
export class SignUpComponent implements OnInit {

  signinForm: FormGroup;
  error= '';
  Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 2000,
    timerProgressBar: true
  })
  constructor(private formBuilder: FormBuilder, private readonly userService: UserService, private router: Router) {
    this.signinForm = this.formBuilder.group({
      email: ['', Validators.required],
      password: ['', Validators.required],
      first_name: ['', Validators.required],
      last_name: ['', Validators.required]
    });
  }

  ngOnInit() {
  }

  onSubmit() {
    if (this.signinForm.valid) {
      this.error= '';
      const data: any = {        
        first_name: this.signinForm.value.first_name,
        last_name: this.signinForm.value.last_name,
        email: this.signinForm.value.email,
        password: this.signinForm.value.password,
        re_password: this.signinForm.value.password,
      };
      Swal.fire({
        title: 'Creazione in corso...',
        didOpen: () => {
          Swal.showLoading()
        },
      })
      this.userService.singin(data).subscribe(
        (response) => {
          Swal.hideLoading();
          Swal.fire({
            position: 'center',
            icon: 'success',
            title: 'Account creato correttamente',
            showConfirmButton: false,
            timer: 1500
          }).then(() => {
            this.router.navigate(['/']);
          })
        },
        (error) => {
          Swal.hideLoading();
          this.Toast.fire({
            icon: 'error',
            title: 'Ops'
          });
          error?.error?.password.forEach(er => {
            this.error += er + ' ';
          });
          console.error(error);
        }
      );
    }
  }
  

}
