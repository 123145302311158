export interface User {
    id?: string;
    first_name: string;
    last_name: string;
    email: string;
    password: string;
    re_password?: string;
}

export interface LogResponse {
    refresh: string;
    access: string;
}

export interface LogBody {
    email: string;
    password: string;
}