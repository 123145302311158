import { Component, Input, ViewEncapsulation } from '@angular/core';
import { LoaderService } from 'src/app/services/loader.service';

@Component({
  selector: 'app-generic-loader',
  templateUrl: './generic-loader.component.html',
  styleUrls: ['./generic-loader.component.scss'],
  encapsulation: ViewEncapsulation.ShadowDom
})
export class GenericLoaderComponent {

  constructor(public loader: LoaderService) { }

}
