import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterVideo'
})
export class FilterVideoPipe implements PipeTransform {
  transform(videos: any[], searchText: string): any[] {
    if (!videos) return [];
    if (!searchText) return videos;
    searchText = searchText.toLowerCase();
    return videos.filter(video => video.name.toLowerCase().includes(searchText));
  }
}
