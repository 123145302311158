<!--calculate bmi start-->
<!-- <section class="gym format bg-cal-bmi" id="bmi" [ngStyle]="{'background-image': 'url(assets/images/gym/calculat-bg.jpg)'}"> -->
    <!-- <div class="container"> -->
        <section class="music bg-footer" [ngStyle]="{'background-image': 'url(assets/images/gym/test.jpg)'}">
            <div class="container">
                <!-- <div class="row"> -->
                    <!-- <div class="col-sm-4">
                        <div class="contact-details text-center">
                            <h4 class="contact-heading text-white ">Show address</h4>
                            <h6 class="contact-sub-text text-white">Exllasa Mall,</h6>
                            <h6 class="contact-sub-text text-white">City mall, marid.</h6>
                        </div>
                    </div>
                    <div class="col-sm-4">
                        <div class="contact-details text-center">
                            <h4 class="contact-heading text-white ">Show address</h4>
                            <h6 class="contact-sub-text text-white">Exllasa Mall,</h6>
                            <h6 class="contact-sub-text text-white">City mall, marid.</h6>
                        </div>
                    </div>
                    <div class="col-sm-4">
                        <div class="contact-details text-center">
                            <h4 class="contact-heading  text-white">Show address</h4>
                            <h6 class="contact-sub-text text-white">Exllasa Mall,</h6>
                            <h6 class="contact-sub-text text-white">City mall, marid.</h6>
                        </div>
                    </div> -->
                <!-- </div> -->
                <div class="form form-footer p-t-50">
                    <div class="row">
                        <div class="col-sm-6">
                            <div class="form-group">
                                <input type="text" placeholder="nome">
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="form-group">
                                <input type="text" placeholder="email">
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="form-group">
                                <textarea class="form-control" rows="6" placeholder="messaggio"></textarea>
                            </div>
                        </div>
                        <div class="booking">
                            <a class="btn btn-default primary-btn m-0-auto bg-black">Invia</a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!-- <div class="row">
            <div class="col-md-6 ">
                <div class=" center-text">
                    <div>
                        <div class="format-small-text">
                            <h6 class="borders-before text-white"><span>Edoardo Dall' Olio</span></h6>
                        </div>
                        <div class="format-head-text">
                            <h3 class="about-font-header" style="color:#ec5c15">Calcola la tua BMI</h3>
                        </div>
                        <div class="format-sub-text">
                            <p class="p-light about-para text-black">Prova gratuitamente a calacolare la tua BMI.</p>
                        </div>
                        <div class="link-horizontal">
                            <ul>
                                <li class="">
                                    <div class="checkbox">
                                        <label class="text-black center-text"><input type="checkbox" value="">Metric
                                            Units</label>
                                    </div>
                                </li>
                                <li class="about-icon">
                                    <div class="checkbox">
                                        <label class="text-black center-text"><input type="checkbox" value="">Imperial
                                            Units</label>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div class="link-horizontal m-t-40 m-b-40">
                            <ul>
                                <li>
                                    <a class="btn btn-default primary-btn transparent">Weight/Kg</a>
                                </li>
                                <li>
                                    <a class="btn btn-default primary-btn transparent">Height/Cm</a>
                                </li>
                            </ul>
                        </div>
                        <a class="btn btn-default white-black">CALCULATE</a>
                    </div>
                </div>
            </div>
            <div class="col-lg-5 offset-lg-1 col-md-6">
                <div class="center-text">
                    <table class="calculate-bmi">
                        <tr>
                            <td>BMI</td>
                            <td>Weight Status</td>
                        </tr>
                        <tr>
                            <td>Below 18.5</td>
                            <td>Underweight</td>
                        </tr>
                        <tr>
                            <td>18.5 - 24.9</td>
                            <td>Normal</td>
                        </tr>
                        <tr>
                            <td>25 - 29.9</td>
                            <td>Overweight</td>
                        </tr>
                        <tr>
                            <td>30 and Above</td>
                            <td>Obese</td>
                        </tr>
                    </table>
                </div>
            </div>
        </div> -->
    <!-- </div> -->
<!-- </section> -->
<!--calculate bmi end-->
