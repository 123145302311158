import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CartService } from '../carrello/cart.service';

@Component({
  selector: 'app-video',
  templateUrl: './video.component.html',
  styleUrls: ['./video.component.scss']
})
export class VideoComponent implements OnInit {

  
  schedulecarousel = [
    { 
      // tagLine1:"#1 Giorno ",
      // tagLine2:"Lunedì",
      description1:"Il fatto determinante è la QUALITA’ NON LA QUANTITA’ (FARE DI PIU’ NON VUOL DIRE NECESSARIAMENTE MEGLIO). ",
      description2:"Per qualità si intende (da sempre è così in ambito fitness e bodybuilding) la corretta esecuzione tecnica degli esercizi. Purtroppo il web è pieno zeppo di materiale contraddittorio, nella quale la persona non riesce a distinguere quale materiale è effettivamente affidabile. Metti fine alla confusione e inizia il tuo percorso di trasformazione oggi stesso!"
    },

  ]
  
  schedulecarouselOptions= {
    items: 1,
    margin: 0,
    dots: true,
    nav: false,
    autoplay: false,
    slideSpeed: 300,
    paginationSpeed: 400,
    loop: true,
  };

  constructor(private router: Router, private cartService: CartService) {}

  ngOnInit() {
  }
  // goToCart(item: any) {
  //   this.router.navigate(['/carrello'], { queryParams: {  price: item.price,
  //     description: item.features1 } });
  //   console.log("clicked")
  // }
  goToCart(item: any) {
    // da aggiungere quando si avrà l'ecommerce 
    // this.cartService.addToCart(item, 1);

    // this.router.navigate(['/login'], { queryParams: {  price: item.price,
    //   description: item.features1 } });
    this.router.navigateByUrl('https://www.precisioncoaching.info/');
  }

  pricing1 = [
    { heading:"3 mesi di coaching premium",
      name: "prodotto1",
      old_price:"300",
      price:"200",
      features1:"Anamnesi iniziale on/offline più successivi controlli",
      features2:"Piano alimentare personalizzato",
      features3:"24 settimane di programmazione dall'allenamento",
      features4:"accesso illimitato ai video tutorials",
      features5:"piano di integrazione personalizzato",
      features6:"Reperibilità tramite WhatsApp Business",
      btn:"Scopri come",
      img: "assets/images/app_landing1/pricing/11.png" 
    },
    { heading:"6 mesi di coaching premium",
    name: "prodotto2",
    old_price:"400",
      price:"300",
      features1:"Anamnesi iniziale on/offline più successivi controlli",
      features2:"Piano alimentare personalizzato",
      features3:"12 settimane di programmazione dall'allenamento",
      features4:"accesso illimitato ai video tutorials",
      features5:"piano di integrazione personalizzato",
      features6:"Reperibilità tramite WhatsApp Business / email",
      features7:"Braccialetto PC Precision Coaching",
      features8:"Possibilità di congelamento della collaborazione fino a un massimo di 30 giorni",
      btn:"Scopri come",
      img: "assets/images/app_landing1/pricing/22.png" 
    },
    { heading:"Annuale coaching premium",
    old_price:"1200",
      price:"1000",
      name: "prodotto3",
      features1:"Anamnesi iniziale on/offline più successivi controlli",
      features2:"Piano alimentare personalizzato",
      features3:"52 settimane di programmazione dall'allenamento",
      features4:"accesso illimitato ai video tutorials",
      features5:"piano di integrazione personalizzato",
      features6:"Reperibilità tramite WhatsApp Business / email",
      features7:"Braccialetto PC Precision Coaching",
      features8:"Possibilità di congelamento della collaborazione fino a un massimo di 30 giorni",
      btn:"Scopri come",
      img: "assets/images/app_landing1/pricing/11.png" 
    }
  ];

  // 3 CASELLE DI COACHING ONLINE PREMIUM CON I RELATIVI PULSANTI DI ACQUISTO


  pricingcarouselOptions1= {
    items: 3,
    margin: 30,
    nav: false,
    autoplay: true,
    slideSpeed: 300,
    paginationSpeed: 400,
    loop: true,
    dots: false,
    responsive: {
        0: {
            items: 1,
            margin: 10
        },
        600:{
            items: 2,
            margin: 10
        },
        992: {
            items: 3
        }
    }
  }

  headercarousel = [
    { 
      tagLine1:"build your",
      tagLine2:"Self",
      description:"Inizia oggi, inizia da te stesso."    
    },
    { 
      tagLine1:"build your",
      tagLine2:"body more strong",
      description:"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took."    
    },  
  ]
  
  headercarouselOptions= {
    items: 1,
    margin: 0,
    autoHeight: true,
    nav: false,
    autoplay: false,
    slideSpeed: 300,
    paginationSpeed: 400,
    loop: true,
  }


  // schedulecarousel = [
  //   { 
  //     tagLine1:"Scheda di video",
  //     tagLine2:"come funziona?",
  //     description1:"All'interno della dettagliata anamnesi iniziale, verrà considerata anche la parte di video. L'video è una scienza, va programmato in base alle tue esigenze e alla tua soggettività. Quando ci troviamo ad iniziare un nuovo percorso assieme, sarà importante non solo stilare una semplice lista di esercizi da effettuare, ma anche raccogliere una serie di dati personali per poter adattare e personalizzare il programma sia agli obiettivi che alla condizione fisica. Ne consegue che, prima di impostare qualsiasi video, verranno eseguiti dei test di valutazione/efficienza corporea (che variano da soggetto a soggetto).Questo mi permetterà di: ",
  //     description2:"- Individuare eventuali problematiche di mobilità/stabilità",
  //     description3:"- Correggere l'esecuzione tecnica degli esercizi",
  //     description4:"- Riprodurre assieme gli esercizi che verranno assegnati nel programma",
  //     description5:"- Stabilire assieme gli obbiettivi in termini di video",

  //   } 
  // ]

  // schedulecarouselOptions= {
  //   items: 1,
  //   margin: 0,
  //   dots: true,
  //   nav: false,
  //   autoplay: false,
  //   slideSpeed: 300,
  //   paginationSpeed: 400,
  //   loop: true,
  // }

}
