import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-gym-bmi',
  templateUrl: './gym-bmi.component.html',
  styleUrls: ['./gym-bmi.component.scss']
})
export class GymBMIComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
