import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-gym-header',
  templateUrl: './gym-header.component.html',
  styleUrls: ['./gym-header.component.scss']
})
export class GymHeaderComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }
  headercarousel = [
    { 
      tagLine1:"NUTRIZIONISTA SPORTIVO E PERSONAL TRAINER ",
      tagLine2:" Dott. In scenze motorie e biologo nutrizionista sportivo",
      description:"Inizia il tuo percorso personalizzato verso il tuo nuovo stile di vita."    
    }
  ]
  
  headercarouselOptions= {
    items: 1,
    margin: 0,
    autoHeight: true,
    nav: false,
    autoplay: false,
    slideSpeed: 300,
    paginationSpeed: 400,
    loop: true,
  }
}
