import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

// Menu
export interface Menu {
	path?: string;
	title?: string;
   type?: string;
   icon?:string;
	badgeType?: string;
	badgeValue?: string;
   active?: boolean;
   megaMenu?: boolean;
   megaMenuType?: string; // small, medium, large
	bookmark?: boolean;
	children?: Menu[];
}

@Injectable({
  providedIn: 'root'
})
export class NavService {

  constructor() {   }

  MENUITEMS: Menu[] = [
	 {title: 'HomePage',  path: '/gym', type: 'link'},
    {
			title: 'I miei prodotti', type: 'sub', children: [
	      	{ path: '/allenamento', title: 'allenamento', type: 'link' },
				{ path: '/nutrizione', title: 'nutrizione', type: 'link' },
            { path: '/landingpage', title: 'news', type: 'link', },
            // { path: '/event', title: 'Event', type: 'link' }
         ],
    
    },
    { title: 'Chi sono',  path: '/about-me', type: 'link'},
    { title: 'Contatti',  path: '/contact', type: 'link'},

   ]

	items = new BehaviorSubject<Menu[]>(this.MENUITEMS);   
}
