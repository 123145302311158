<!-- <section class="" style="background-color: grey;" id="home"></section>

<div *ngIf="video">
    <h2>{{ video.titolo }}</h2>
    <video controls>
      <source [src]="video.url" type="video/mp4">
      Il tuo browser non supporta il video.
    </video>
  </div> -->
  <!-- <h2>{{ selectedVideo.title }}</h2> -->
  <div *ngIf="video">
    <h2>{{ video.titolo }}</h2>
    <h2>{{ video.descrizione }}</h2>
    <iframe src="https://player.vimeo.com/video/860454072?h=7ce3c124b7" width="640" height="564" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
  </div>
  <div class="row" *ngIf="selectedVideo?.url else ">
    <!-- Includi qui la logica per visualizzare il video selezionato -->
    <!-- <video class="col-8" controls>
        <source [src]="selectedVideo?.url" type="video/mp4">
        Il tuo browser non supporta il video.
      </video> -->

      <iframe src="https://player.vimeo.com/video/860454072?h=7ce3c124b7" width="640" height="344" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>

    <div class="col-4">      <p>esecuzione: {{ selectedVideo?.descrizione }}</p>
    </div>

  </div>