<!--schedule section start-->
<app-gym-nav></app-gym-nav>

<section class="gym header" id="home">
    <div class="header5-content">
            <owl-carousel-o [options]="headercarouselOptions" class="default-dots gym-slider">
                    <ng-container *ngFor="let header of headercarousel">
                        <ng-template carouselSlide class="item">
                            <!-- HEX : #ec5c15
RGB : 236, 92, 21
HSL  : 19, 84%, 50% -->
                <div class="gym-header bg" [ngStyle]="{'background-image': 'url(assets/images/gym/bg_header_1920x1100.png)'}">
                    <div class="container">
                        <div class="row">
                            <div class="col-md-10 offset-md-3">
                                <div class="center-text">
                                    <div class="text-center">
                                        <div class="header-text">
                                            <!-- <h1 style="color:black"><span class="bold-text" style="color:#ec5c15">{{header.tagLine1}}</span>{{header.tagLine2}}</h1> -->
                                        </div>
                                        <div class="header-sub-text">
                                            <!-- <p class="p-light" style="color:black">{{header.description}}</p> -->
                                        </div>
                                        <!-- <div class="link-horizontal">
                                            <ul class="justify-content-center" >
                                                <li>
                                                    <a class=" btn btn-default" style="color:black"><span>register 20%<sup>off</sup></span></a>
                                                </li>
                                                <li>
                                                    <a class=" btn btn-default">start now</a>
                                                </li>
                                            </ul>
                                        </div> -->
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                </ng-template>
                </ng-container>
                </owl-carousel-o>
              </div>
</section>


<section class="gym format " style="background-size: 1300px;" id="schedule" >
    <div class="container">
        <div class="row">
            <div class="col-md-10 offset-md-1">
                <div class="title">
                    <div class="main-title">
                        <h2 class="text-black m-b-15">I 3 step del primo incontro</h2>
                    </div>
                    <img src="assets/images/logo/l5-title-bottom.png" alt="" class="img-fluid">
                </div>
            </div>
            <div class="col-12">
                    <owl-carousel-o [options]="schedulecarouselOptions" class="default-dots gym-about-slider">
                            <ng-container *ngFor="let schedule of schedulecarousel">
                                <ng-template carouselSlide class="row">
                        <div class="col-lg-6 item">
                            <div class="center-text">
                                <div>
                                    <div class="format-small-text">
                                        <h6 class="text-black borders-before"><span>{{schedule.tagLine1}}</span></h6>
                                    </div>
                                    <div class="format-head-text">
                                        <h3 class=" about-font-header" style="color:#ec5c15">{{schedule.tagLine2}}</h3>
                                    </div>
                                    <div class="format-sub-text">
                                        <p class="p-light about-para text-black">{{schedule.description1}}</p>
                                        <p class="p-light about-para text-black">{{schedule.description2}}</p>
                                        <p class="p-light about-para text-black">{{schedule.description3}}</p>

                                        <p class="p-light about-para text-black">{{schedule.description4}}</p>

                                        <p class="p-light about-para text-black">{{schedule.description5}}</p>

                                    </div>
                                    <!-- <ul class="icon-collection">
                                        <li class="about-icon">
                                            <a href="#" class="center-content"><img
                                                    src="assets/images/gym/icons/2.png" alt=""
                                                    class="img-fluid icons"></a>
                                        </li>
                                        <li class="about-icon">
                                            <a href="#" class="center-content"><img
                                                    src="assets/images/gym/icons/1.png" alt=""
                                                    class="img-fluid icons"></a>
                                        </li>
                                        <li class="about-icon">
                                            <a href="#" class="center-content"><img
                                                    src="assets/images/gym/icons/3.png" alt=""
                                                    class="img-fluid icons"></a>
                                        </li>
                                    </ul> -->
                                    <!-- <a  href="/contact" class="btn btn-default back-black m-t-45">Incontriamoci</a> -->
                                </div>

                            </div>

                        </div>

                    </ng-template>
                    </ng-container>
                    </owl-carousel-o>
                    <!-- <div class="row">
                        <div class="col-lg-5 item ">
                            <div class=" center-text">
                                <div>
                                    <div class="format-small-text">
                                        <h6 class="text-white borders-before"><span>#1 Day Training</span></h6>
                                    </div>
                                    <div class="format-head-text">
                                        <h3 class="text-white about-font-header">Monday</h3>
                                    </div>
                                    <div class="format-sub-text">
                                        <p class="p-light about-para text-white">Lorem Ipsum is simply dummy text of the
                                            printing
                                            and
                                            typesetting industry. Lorem Ipsum has been the industry's standard dummy
                                            text
                                            ever
                                            since
                                            the 1500s</p>
                                        <p class="p-light about-para text-white">Lorem Ipsum has been the industry's
                                            standard
                                            dummy
                                            text
                                            ever since the 1500s</p>
                                    </div>
                                    <ul class="icon-collection">
                                        <li class="about-icon">
                                            <a href="#" class="center-content"><img
                                                    src="assets/images/gym/icons/2.png" alt=""
                                                    class="img-fluid icons"></a>
                                        </li>
                                        <li class="about-icon">
                                            <a href="#" class="center-content"><img
                                                    src="assets/images/gym/icons/1.png" alt=""
                                                    class="img-fluid icons"></a>
                                        </li>
                                        <li class="about-icon">
                                            <a href="#" class="center-content"><img
                                                    src="assets/images/gym/icons/3.png" alt=""
                                                    class="img-fluid icons"></a>
                                        </li>
                                    </ul>
                                    <a class="btn btn-default back-white m-t-45">learn more</a>
                                </div>
                            </div>
                        </div>
                    </div> -->
            </div>
        </div>
    </div>
</section>


<app-gym-footer></app-gym-footer>
<app-gym-copyright></app-gym-copyright>
