<a  class="overlay-sidebar-header" [class.overlay-sidebar-header-open]="openSide" (click)="closeOverlay()"></a>
<div class="responsive-btn">
    <a (click)="toggleSidebar()">
        <i class="fa fa-bars text-white" aria-hidden="true"></i>
    </a>
</div>
<div class="navbar m-l-auto" id="togglebtn" [class.openSidebar]="openSide" >
    <div class="responsive-btn">
        <a (click)="toggleSidebar()">
            <h5>back</h5>
        </a>
    </div>
    <ul class="main-menu">
        <!-- 1st Level Menu -->
        <li *ngFor="let menuItem of menuItems" [class]="menuItem.megaMenu ? 'mega-menu' : ''">
            <!-- Sub -->
            <a href="javascript:void(0)" *ngIf="menuItem.type === 'sub'" class="dropdown" (click)="setActive(menuItem.title)">
                {{menuItem.title}}
            </a>
            <!-- Link -->
            <a [routerLink]="!menuItem.type ? null : [menuItem.path]" routerLinkActive="active" class="dropdown" *ngIf="menuItem.type === 'link'">
                {{menuItem.title}}
            </a>
            <!-- External Link -->
            <a  href="{{ !menuItem.type ? null : menuItem.path }}" class="dropdown" *ngIf="menuItem.type === 'extLink'">
                {{menuItem.title}}
            </a>
            <!-- External Tab Link -->
            <a  href="{{ !menuItem.type ? null : menuItem.path }}" *ngIf="menuItem.type === 'extTabLink'">
                {{menuItem.title}}
            </a>

            <!-- Mega menu -->
            <div class="mega-menu-container" [class.opensubmenu]="isActive(menuItem.title)"
                *ngIf="menuItem.megaMenu && menuItem.megaMenuType == 'small' || menuItem.megaMenuType == 'medium' || menuItem.megaMenuType == 'large'">
                <div class="container">
                    <div class="row">
                        <div [class]="menuItem.megaMenuType == 'small' ? 'col-lg-4' : menuItem.megaMenuType == 'medium' ? 'col-lg-3' : menuItem.megaMenuType =='large'? 'col' :'' "
                            *ngFor="let childrenItem of menuItem.children">
                            <div class="menu-container">
                                    <a class="menu-head" (click)="setChildActive(childrenItem.title)">{{childrenItem.title}}</a>
                                <ul [class.menu-icon]="menuItem.title == 'Elements'" [class.openSubChildMenu]="ischildActive(childrenItem.title)" >
                                    <li  *ngFor="let subChildrenItem of childrenItem.children" >
                                        <a [routerLink]="!subChildrenItem.type ? null : [subChildrenItem.path]" routerLinkActive="active"
                                            *ngIf="subChildrenItem.type === 'link'">
                                            <i class="icon-{{subChildrenItem.icon}}"></i>{{subChildrenItem.title}}</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
            <!-- 2nd Level Menu -->
            <ul *ngIf="menuItem.children && !menuItem.megaMenu" [class.opensubmenu]="isActive(menuItem.title)">
                    <!-- Simple Menu Start-->
                    <li *ngFor="let childrenItem of menuItem.children"  [class.sub-menu]="childrenItem.children">
                        <!-- Sub -->
                        <a href="javascript:void(0)" *ngIf="childrenItem.type === 'sub'" (click)="setChildActive(childrenItem.title)">
                                {{childrenItem.title}}
                             </a>
                        <!-- Link -->
                        <a [routerLink]="!childrenItem.type ? null : [childrenItem.path]" routerLinkActive="active"
                            *ngIf="childrenItem.type === 'link' && !menuItem.megaMenu">
                            {{childrenItem.title}}
                        </a>
                        <!-- External Link -->
                        <a href="{{ !childrenItem.type ? null : childrenItem.path }}"
                            *ngIf="childrenItem.type === 'extLink' && !menuItem.megaMenu">
                            {{childrenItem.title}}
                        </a>
                        <!-- External Tab Link -->
                        <a href="{{ !childrenItem.type ? null : childrenItem.path }}" target="_blank"
                            *ngIf="childrenItem.type === 'extTabLink' && !menuItem.megaMenu">
                            {{childrenItem.title}}
                        </a>
                        <ul *ngIf="childrenItem.children" [class.openSubChildMenu]="ischildActive(childrenItem.title)">
                                <li *ngFor="let subChildrenItem of childrenItem.children">
                                      <!-- Sub -->
                                         <a href="javascript:void(0)" *ngIf="subChildrenItem.type === 'sub'">
                                            {{subChildrenItem.title}}
                                         </a>
                                        <!-- Link -->
                                        <a [routerLink]="!subChildrenItem.type ? null : [subChildrenItem.path]" routerLinkActive="active"
                                            *ngIf="subChildrenItem.type === 'link' && !menuItem.megaMenu">
                                            {{subChildrenItem.title}}
                                        </a>
                                        <!-- External Link -->
                                        <a href="{{ !subChildrenItem.type ? null : subChildrenItem.path }}"
                                            *ngIf="subChildrenItem.type === 'extLink' && !menuItem.megaMenu">
                                            {{subChildrenItem.title}}
                                        </a>
                                        <!-- External Tab Link -->
                                        <a href="{{ !subChildrenItem.type ? null : subChildrenItem.path }}" target="_blank"
                                            *ngIf="subChildrenItem.type === 'extTabLink' && !menuItem.megaMenu">
                                            {{subChildrenItem.title}}
                                        </a>
                                </li>
                        </ul>
                    </li>
            </ul>
        
        </li>
    </ul>
    
    <div class="top-header-right">
        <ul> 
            <!-- da aggiungere quando carrello pronto  -->
            <!-- <li class="cart">
                <a>
                    <i class="icon-shopping-cart-full" (click)="showItem = !showItem"></i>
                </a>
                <span class="cart_qty_cls">{{shoppingCartItems.length}}</span>
                <div class="dropdown-menu dropdown-menu-right" [class.show]="showItem">
                  <ul class="shopping-cart" *ngIf='shoppingCartItems.length'>
                      <li *ngFor="let item of shoppingCartItems">
                        <div class="media">
                          <a [routerLink]="['/carrello', item.product.id]"><img class="mr-3" [src]="item.product.img" alt="Generic placeholder image"></a>
                          <div class="media-body">
                            <a [routerLink]="['/home/left-sidebar/product', item.product.id]"><h4>{{ item.product.name }}</h4></a>
                            <h4><span>{{ item.quantity }} x {{ item.product.price | currency:productService?.currency:'symbol' }}</span></h4>
                          </div>
                        </div>
                        <div class="close-circle">
                          <a (click)="removeItem(item)"><i class="fa fa-times" aria-hidden="true"></i></a>
                        </div>
                      </li>
                      <li>
                        <div class="total">
                          <h5>subtotal : <span>{{ getTotal() | async | currency:productService?.currency:'symbol'}}</span></h5>
                        </div>
                      </li>
                      <li>
                        <div class="buttons">
                          <a [routerLink]="'/carrello'" class="view-cart">view cart</a>
                          <a [routerLink]="'/checkout'" class="checkout">checkout</a>
                        </div>
                      </li>
                    </ul>
                </div>
            </li> -->
            <li class="account ">
                <a aria-expanded="false" data-toggle="dropdown" href="/login" id="dropdownMenuButton2">
                    <i class="icon-user"></i>
                </a>
                <div aria-labelledby="dropdownMenuButton2" class="dropdown-menu show dropdown-menu-right">
                    <a class="dropdown-item" href="/login">Login</a>
                    <a class="dropdown-item" (click)="logout()">Logout</a>
                    <!-- <a class="dropdown-item" href="#">Whishlist</a>
                    <a class="dropdown-item" href="#">Checkout</a> -->
                </div>
            </li>
        </ul>
    </div>
</div>
  
