import { Component, OnInit } from '@angular/core';
import {CartItem} from './cart.model';
import { Observable, of } from 'rxjs';
import { CartService } from './cart.service';
@Component({
  selector: 'app-carrello',
  templateUrl: './carrello.component.html',
  styleUrls: ['./carrello.component.scss']
})

export class CarrelloComponent implements OnInit{
  public cartItems: Observable<CartItem[]> = of([]);
  public shoppingCartItems: CartItem[] = [];

  constructor(
    private cartService: CartService) { }

  ngOnInit() {
    this.cartItems = this.cartService.getItems();
    console.log(this.cartItems);
    this.cartItems.subscribe(shoppingCartItems => this.shoppingCartItems = shoppingCartItems);
  }

  // Increase Product Quantity
  public increment(product: any, quantity: number = 1) {
    this.cartService.updateCartQuantity(product, quantity);
  }

  // Decrease Product Quantity
  public decrement(product: any, quantity: number = -1) {
    this.cartService.updateCartQuantity(product, quantity);
  }

  // Get Total
  public getTotal(): Observable<number> {
    return this.cartService.getTotalAmount();
  }

  // Remove cart items
  public removeItem(item: CartItem) {
    this.cartService.removeFromCart(item);
  }
  // item: any;
  // shoppingCartItems= [];
  // constructor(private route: ActivatedRoute) {
  //   this.route.queryParams.subscribe(params => {
  //     this.item = {
  //       price: params['price'],
  //       description: params['description']
  //     };
  //     console.log(this.item)
  //   });
  // }
}
