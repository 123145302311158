// src/app/video.service.ts

import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class VideoService {
  private videoCorsi = [
    { id: 1, titolo: 'Squat', url: 'assets/video/bs.mp4', descrizione:'questo é un esempio di descrizione di esecuzione corso ' },
    { id: 2, titolo: 'Affondo bulgaro', url: 'assets/video/Affondi manubri in avanzamento.mov', descrizione:'questo é un esempio di descrizione di esecuzione corso '  },
    { id: 3, titolo: 'Alzate laterali manubri', url: 'assets/video/Alzate laterali manubri.mov', descrizione:'questo é un esempio di descrizione di esecuzione corso '  },
    { id: 4, titolo: 'Corso Angular', url: 'assets/video/bs.mp4', descrizione:'questo é un esempio di descrizione di esecuzione corso ' },
    { id: 5, titolo: 'Corso JavaScript', url: 'assets/video/bs.mp4', descrizione:'questo é un esempio di descrizione di esecuzione corso '  },
    { id: 6, titolo: 'Corso TypeScript', url: 'assets/video/bs.mp4', descrizione:'questo é un esempio di descrizione di esecuzione corso '  },
  ];

  getVideoCorsi() {
    return this.videoCorsi;
  }

  getVideoById(id: number) {
    return this.videoCorsi.find(video => video.id === id);
  }
}
