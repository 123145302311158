<!-- header Start-->
<section class="gym header" id="home">
    <div class="header5-content">
            <owl-carousel-o [options]="headercarouselOptions" class="default-dots gym-slider">
                    <ng-container *ngFor="let header of headercarousel">
                        <ng-template carouselSlide class="item">
                            <!-- HEX : #ec5c15
RGB : 236, 92, 21
HSL  : 19, 84%, 50% -->
                <div class="gym-header bg" [ngStyle]="{'background-image': 'url(assets/images/gym/testheader.jpg)'}">
                    <div class="container">
                        <div class="row">
                            <div class="col-md-10 offset-md-3">
                                <div class="center-text">
                                    <div class="text-center">
                                        <div class="header-text">
                                            <h1 style="color:white"><span class="bold-text" style="color:#ec5c15">{{header.tagLine1}}</span></h1>
                                        </div>
                                        <div class="header-sub-text">
                                            
                                            <p class="p-light" style="color: white">{{header.tagLine2}}</p>

                                            <p class="p-light" style="color:#ec5c15">{{header.description}}</p>
                                        </div>
                                        <!-- <div class="link-horizontal">
                                            <ul class="justify-content-center" >
                                                <li>
                                                    <a class=" btn btn-default" style="color:black"><span>register 20%<sup>off</sup></span></a>
                                                </li>
                                                <li>
                                                    <a class=" btn btn-default">start now</a>
                                                </li>
                                            </ul>
                                        </div> -->
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                </ng-template>
                </ng-container>
                </owl-carousel-o>
              </div>
</section>
<!-- header end-->
