<!-- breadcrumb section start -->
<section class="" style="background-color: grey;" id="home"></section>

<section class="breadcrumb-section-main inner-2 breadcrumb-section-sm">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="breadcrumb-contain ">
                        <div>
                            <h2 class="breadcrumb-txt">Rigister</h2>
                        </div>
                        <div>
                            <ul>
                                <li><a href="#">Rigister</a></li>
                                <li><a href="#"><i class="fa fa-angle-double-right"></i>Rigister</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
<!-- section start -->
<section class="register-page section-b-space">
  <div class="container">
      <div class="row">
          <div class="col-lg-12">
              <h3>create account</h3>
              <div class="theme-card">
                  <form class="theme-form" [formGroup]="signinForm" (ngSubmit)="onSubmit()">
                      <div class="form-row">
                          <div class="col-md-6">
                              <label for="email">First Name</label>
                              <input type="text" class="form-control" formControlName="first_name" id="fname" placeholder="First Name" required>
                          </div>
                          <div class="col-md-6">
                              <label for="review">Last Name</label>
                              <input type="text" class="form-control" id="lname" formControlName="last_name" placeholder="Last Name" required>
                          </div>
                      </div>
                      <div class="form-row">
                          <div class="col-md-6">
                              <label for="email">email</label>
                              <input type="email" class="form-control" id="email" formControlName="email"  placeholder="Email" required>
                          </div>
                          <div class="col-md-6">
                              <label for="review">Password</label>
                              <input type="password" class="form-control" id="review" formControlName="password"  placeholder="Enter your password" required>
                          </div>
                          <button type="submit" class="btn primary-btn btn-default radius-0">create Account</button>
                      </div>
                      <div class="error-message mt-4" *ngIf="error">
                        {{ error }}
                      </div>
                  </form>
              </div>
          </div>
      </div>
  </div>
</section>
<!--Section ends-->
