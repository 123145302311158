<!--schedule section start-->
<app-gym-nav></app-gym-nav>
<section class="gym header" id="home">
    <div class="header5-content">
            <!-- <owl-carousel-o [options]="headercarouselOptions" class="default-dots gym-slider">
                    <ng-container *ngFor="let header of headercarousel"> -->
                        <!-- <ng-template carouselSlide class="item"> -->
                            <!-- HEX : #ec5c15
RGB : 236, 92, 21
HSL  : 19, 84%, 50% -->
                <div class="gym-header bg" [ngStyle]="{'background-image': 'url(assets/images/_DSC5681.jpg)'}">
                    <div class="container">
                        <div class="row">
                            <div class="col-md-10 offset-md-3">
                                <div class="center-text">
                                    <div class="text-center">
                                        <div class="header-text">
                                           <h1 style="color:black"><span class="bold-text" style="color:#ec5c15">About me </span></h1>
                                        </div>
                                        <div class="header-sub-text">
                                            <p class="p-light" style="color:white">scopri di più su di me sul mio percorso</p>
                                        </div>
                                        <!-- <div class="link-horizontal">
                                            <ul class="justify-content-center" >
                                                <li>
                                                    <a class=" btn btn-default" style="color:black"><span>register 20%<sup>off</sup></span></a>
                                                </li>
                                                <li>
                                                    <a class=" btn btn-default">start now</a>
                                                </li>
                                            </ul>
                                        </div> -->
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- </ng-template> -->
                <!-- </ng-container>
                </owl-carousel-o> -->
              </div>
</section>

<horizontal-timeline [timelineElements]="timeline"  [showContent]="true"  [timelineWrapperWidth]="2000"></horizontal-timeline>
<!-- <section class="gym format bg-schedule" style="background-size: 1300px;" id="schedule"  >
    <div class="container">
    <ng-vertical-timeline [data]="events"></ng-vertical-timeline>

    </div>
</section> -->


<app-gym-footer></app-gym-footer>
<app-gym-copyright></app-gym-copyright>

<!-- All'interno della dettagliata anamnesi iniziale, verrà considerata anche la parte di allenamento.
L'allenamento è una scienza, va programmato in base alle tue esigenze e alla
tua soggettività. Quando ci troviamo ad iniziare un nuovo percorso assieme, sarà importante
non solo stilare una semplice lista di esercizi da effettuare, ma anche raccogliere una serie di dati
personali per poter adattare e personalizzare il programma sia agli obiettivi che alla condizione
fisica. Ne consegue che, prima di impostare qualsiasi allenamento, verranno eseguiti dei test di
valutazione/efficienza corporea (che variano da soggetto a soggetto).
Questo mi permetterà di:
- Individuare eventuali problematiche di mobilità/stabilità
- Correggere l'esecuzione tecnica degli esercizi
- Riprodurre assieme gli esercizi che verranno assegnati nel programma
- Stabilire assieme gli obbiettivi in termini di allenamento.

Dopo aver raccolto questi dati, potrò preparare il programma personalizzato ed iniziare ad
assisterti nel tuo. percorso allenante.

Durata totale dell'incontro è di circa 45/60 minuti. Inoltre, per chi comincia il percorso di
coaching (LINK A PACCHETTI), si aggiungeranno i dati dell'anamnesi alimentare (LINK A
NUTRIZIONE) -->