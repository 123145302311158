import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-gym-trainer',
  templateUrl: './gym-trainer.component.html',
  styleUrls: ['./gym-trainer.component.scss']
})
export class GymTrainerComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

  
  trainercarousel = [
    { 
     img:"assets/images/gym/traniers/tom460x460.jpg",
     name:'Tom Van Loi'
    },
    { 
      img:"assets/images/gym/traniers/fiorenzuolacalcio460x460.png",
      name:'Fiorenzuola Calcio'
     },
    // { 
    //   img:"assets/images/gym/traniers/ChiaraVincis.jpeg",
    //   name:'Chiara Vincis'
    // },
    { 
      img:"assets/images/gym/traniers/MassimilianoCosta460x460.png",
      name:'Massimiliano Costa'
    },
    { 
      img:"assets/images/gym/traniers/gemma_460x460.png",
      name:'Gemma Ghinelli'
    }
  ]

  trainercarouselOptions= {
    center: true,
    loop: true,
    dots: true,
    margin: 0,
    responsive: {
        0: {
            items: 2,
            margin: 10
        },
        576: {
            items: 3
        },
        768: {
            items: 4
        },
        1200: {
            items: 6
        }
    }
  }

  

}
