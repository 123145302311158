import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-gym-pricing',
  templateUrl: './gym-pricing.component.html',
  styleUrls: ['./gym-pricing.component.scss']
})
export class GymPricingComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }
// for vertical timeline
  // events = [
  //   {
  //     id: 0,
  //     title: 'Laurea scienze motorie e sportive',
  //     content: 'Descrizione di test e di prova, ho preso questa laurea .',
  //     date: '2016 - 2019',
  //     icon: ''
  //   },
  //   {
  //     id: 1,
  //     title: 'My Job',
  //     content: 'Seconda laurea in biologia della nutrizione umana',
  //     date: '2015 - 2016',
  //     icon: ''
  //   },
  //   {
  //     id: 2,
  //     title: 'SNPT',
  //     content: 'This is the university I went...',
  //     date: '2011',
  //     icon: ''
  //   }
  // ];

  pricing = [
    { 
      icon:"", // iconda di sfondo
      package:"3 mesi",
      feature1:"Prima anamnesi iniziale + successivi controlli",
      feature2:"Analisi corporea",
      feature3:"Piano alimentare personalizzato",
      feature4:"12 settimane di programmazione dell’allenamento",
      feature5:"Piano d’integrazione personalizzato",
      feature6:"Reperibilità tramite WhatsApp business/mail",
      price:"285",
      // btn:"Aggiungi"
    },
    {
      icon:"",
      package:"6 mesi",
      feature1:"Prima anamnesi iniziale + successivi controlli",
      feature2:"Analisi corporea",
      feature3:"Piano alimentare personalizzato",
      feature4:"24 settimane di programmazione dell’allenamento",
      feature5:"Piano d’integrazione personalizzato",
      feature6:"Reperibilità tramite WhatsApp business/mail",
      price:"540",
      // btn:"Aggiungi"
    },
    {
      icon:"",
      package:"Annuale",
      feature1:"Prima anamnesi iniziale + successivi controlli",
      feature2:"Analisi corporea",
      feature3:"Piano alimentare personalizzato",
      feature4:"48 settimane di programmazione dell’allenamento",
      feature5:"Piano d’integrazione personalizzato",
      feature6:"Reperibilità tramite WhatsApp business/mail",
      price:"1000",
      // btn:"Aggiungi"
    }
  ]
  
  pricingcarouselOptions= {
    items: 3,
    margin: 30,
    nav: false,
    autoplay: false,
    slideSpeed: 300,
    paginationSpeed: 400,
    loop: true,
    dots: false,
    responsive: {
        0: {
            items: 1,
            margin: 10
        },
        480: {
            items: 2,
            margin: 10
        },
        992: {
            items: 3,
            margin: 10
        }
    }
  }

  
}
