<!-- Nav Start-->
<header class="gym loding-header nav-abs custom-scroll">
    <div class="container">
        <div class="row">
            <div class="col">
                <nav>
                    <a href="#" class="d-inline-block m-r-auto">
                        <img src="assets/images/logo/LogoEdo.png" alt="" style="max-width: 150px; min-width: auto;" class="img-fluid">
                    </a>
                    <app-menu></app-menu>
                    <!-- <ul class="d-flex footer-social social">
                        <li class="footer-social-list">
                            <a href="#"><i class="fa fa-facebook" aria-hidden="true"></i></a>
                        </li>
                        <li class="footer-social-list">
                            <a href="#"><i class="fa fa-instagram" aria-hidden="true"></i></a>
                        </li>
                        <li class="footer-social-list">
                            <a href="#"><i class="fa fa-google" aria-hidden="true"></i></a>
                        </li>
                    </ul> -->
                </nav>
            </div>
        </div>
    </div>
</header>
<!-- Nav end-->