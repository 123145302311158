import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LogResponse, LogBody, User } from './../models/user'
import { Observable } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class UserService {

  private readonly url = 'https://pcprecisioncoaching.it/api/auth';

  constructor(private http: HttpClient) { }

  login(data: any): Observable<LogResponse> {
    return this.http.post<LogResponse>(this.url + '/jwt/create/', data);
  }

  singin(data: any): Observable<any> {
    return this.http.post(this.url + '/users/', data);
  }

  activate(data: any): Observable<any> {
    return this.http.post<any>(this.url + '/users/activation/', data);
  }
}
