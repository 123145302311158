<app-gym-nav></app-gym-nav>

<section class=""  [ngStyle]="{'background-image': 'url(assets/images/_DSC5681.jpg)'}">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class="title title2 title-inner">
                    <div class="main-title">
                        <h2 class="font-secondary borders text-center main-text text-uppercase m-b-0"><span>Contatti</span></h2>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<footer class="event contact set-relative bg bg-img bg-about p-b-0" id="contact"  [ngStyle]="{'background-image': 'url(assets/images/_DSC5681.jpg)'}">
    <div class="container p-b-100">
        <div class="row">
            <div class="col-md-10 offset-md-1">
                <div class="title title3">
                    <div class="main-title">
                        <h2 class=" text-white">Come contattarmi</h2>
                    </div>
                    <div class="sub-title">
                        <P class="text-white">Se hai qualunque tipo di domande riguardate coaching o sulla nutrizione e le faq sotto riportate non ti rispondono alle tue domande, contattami compilando il form!.</P>
                    </div>
                </div>
            </div>
            <div class="col-xl-5 offset-xl-1 col-md-6 p-r-0 map">
                <div class="iframe-container">
                    <div style="max-width:100%;overflow:hidden;color:red;width:500px;height:500px;"><div id="g-mapdisplay" style="height:100%; width:100%;max-width:100%;"><iframe style="height:100%;width:100%;border:0;" frameborder="0" src="https://www.google.com/maps/embed/v1/place?q=FRANCIS+LAB,+Via+Malta,+Brescia,+BS,+Italia&key=AIzaSyBFw0Qbyq9zTFTd-tUY6dZWTgaQzuU17R8"></iframe></div><a class="my-codefor-googlemap" href="https://www.bootstrapskins.com/themes" id="grab-map-authorization">premium bootstrap themes</a><style>#g-mapdisplay img.text-marker{max-width:none!important;background:none!important;}img{max-width:none}</style></div>
                </div>
            </div>
            <div class="col-xl-5 p-l-0 col-md-6 set-z-index form-footer">
                <div class="bg-white">

                    <form>
                        <div class="row">
                            <div class="col-sm-6">
                                <div class="form-group">
                                    <label for="name">Nome *</label>
                                    <input type="text" class="form-control" id="name">
                                </div>
                            </div>
                            <div class="col-sm-6">
                                <div class="form-group">
                                    <label for="Phone">Telefono *</label>
                                    <input type="text" class="form-control" id="Phone">
                                </div>
                            </div>
                            <div class="col-sm-6">
                                <div class="form-group">
                                    <label for="Email">Email *</label>
                                    <input type="text" class="form-control" id="Email">
                                </div>
                            </div>
                            <div class="col-sm-6">
                                <div class="form-group">
                                    <label for="Subject">Oggetto *</label>
                                    <input type="text" class="form-control" id="Subject">
                                </div>
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="Message">Messaggio *</label>
                            <input type="text" class="form-control" id="Message">
                        </div>

                        <a class="btn btn-default primary-btn m-0-auto event-btn ">Invia</a>
                    </form>
                </div>
            </div>
        </div>
    </div>
    <img src="assets/images/event/footer/2.png" alt="" class="set-abs top-0 plane2">
    <img src="assets/images/event/footer/1.png" alt="" class="set-abs bottom-0 plane">
</footer>

<section class=" bg-light-inner p-b-0">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class="title title2 title-inner">
                    <div class="main-title">
                        <h2 class="font-primary borders text-center main-text text-uppercase m-b-0"><span>Dove mi puoi trovare</span></h2>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- <div style="width: 100%"><iframe width="100%" height="600" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=Via%20Triumplina,%2094,%2025123%20Brescia%20BS+()&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"><a href="https://www.maps.ie/distance-area-calculator.html">distance maps</a></iframe></div> -->
    <!-- <div style="width: 100%"><iframe width="100%" height="600" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=Via%20XXV%20Aprile,%2034,%2029010%20San%20Nicol%C3%B2%20PC+(Tennuoto)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"><a href="https://www.maps.ie/distance-area-calculator.html">area maps</a></iframe></div> -->
<section class="music bg-footer" [ngStyle]="{'background-image': 'url(assets/images/music/footer-bg.jpg)'}">
    <div class="container">
        <div class="row">
            <div class="col-sm-4">
                <div class="contact-details text-center">
                    <h4 class="contact-heading text-Black ">Centro Fisioterapico Tennuoto</h4>
                    <h6 class="contact-sub-text text-Black">Via XXV Aprile, 34</h6>
                    <h6 class="contact-sub-text text-black">29010 San Nicolò PC, Italia</h6>
                    <div class="iframe-container">
                        <div style="max-width:100%;overflow:hidden;color:red;width:500px;height:500px;"><div id="g-mapdisplay" style="height:100%; width:100%;max-width:100%;"><iframe style="height:100%;width:100%;border:0;" frameborder="0" src="https://www.google.com/maps/embed/v1/place?q=Via+XXV+Aprile,+34,+29010+San+Nicolò,+PC,+Italia&key=AIzaSyBFw0Qbyq9zTFTd-tUY6dZWTgaQzuU17R8"></iframe></div><a class="my-codefor-googlemap" href="https://www.bootstrapskins.com/themes" id="grab-map-authorization">premium bootstrap themes</a><style>#g-mapdisplay img.text-marker{max-width:none!important;background:none!important;}img{max-width:none}</style></div>
                    </div>
                </div>
            </div>
            <div class="col-sm-4">
                <div class="contact-details text-center">
                    <h4 class="contact-heading text-Black ">Francis Lab</h4>
                    <h6 class="contact-sub-text text-Black">Via Malta 4E</h6>
                    <h6 class="contact-sub-text text-Black">25127 Brescia</h6>
                    <div class="iframe-container">
                        <div style="max-width:100%;overflow:hidden;color:red;width:500px;height:500px;"><div id="g-mapdisplay" style="height:100%; width:100%;max-width:100%;"><iframe style="height:100%;width:100%;border:0;" frameborder="0" src="https://www.google.com/maps/embed/v1/place?q=FRANCIS+LAB,+Via+Malta,+Brescia,+BS,+Italia&key=AIzaSyBFw0Qbyq9zTFTd-tUY6dZWTgaQzuU17R8"></iframe></div><a class="my-codefor-googlemap" href="https://www.bootstrapskins.com/themes" id="grab-map-authorization">premium bootstrap themes</a><style>#g-mapdisplay img.text-marker{max-width:none!important;background:none!important;}img{max-width:none}</style></div>
                    </div>
                </div>
            </div>
            <div class="col-sm-4">
                <div class="contact-details text-center">
                    <h4 class="contact-heading  text-Black">Studio Aura</h4>
                    <h6 class="contact-sub-text text-Black">17, scala C, Str. Gragnana</h6>
                    <h6 class="contact-sub-text text-Black">29121 Piacenza PC</h6>
                    <div style="max-width:100%;overflow:hidden;color:red;width:500px;height:500px;"><div id="g-mapdisplay" style="height:100%; width:100%;max-width:100%;"><iframe style="height:100%;width:100%;border:0;" frameborder="0" src="https://www.google.com/maps/embed/v1/place?q=17,+Scala+C,+Str.+Gragnana+29121+Piacenza+PC&key=AIzaSyBFw0Qbyq9zTFTd-tUY6dZWTgaQzuU17R8"></iframe></div><a class="my-codefor-googlemap" href="https://www.bootstrapskins.com/themes" id="grab-map-authorization">premium bootstrap themes</a><style>#g-mapdisplay img.text-marker{max-width:none!important;background:none!important;}img{max-width:none}</style></div>
                </div>
            </div>
        </div>

        <!-- <div class="form form-footer p-t-50">
            <div class="row">
                <div class="col-sm-6">
                    <div class="form-group">
                        <input type="text" placeholder="your name">
                    </div>
                </div>
                <div class="col-sm-6">
                    <div class="form-group">
                        <input type="text" placeholder="your email">
                    </div>
                </div>
                <div class="col-12">
                    <div class="form-group">
                        <textarea class="form-control" rows="6" placeholder="your message"></textarea>
                    </div>
                </div>
                <div class="booking">
                    <a class="btn btn-default primary-btn m-0-auto bg-black">send</a>
                </div>
            </div>
        </div> -->
    </div>


</section>
</section>
<section class="music bg-footer" [ngStyle]="{'background-image': 'url(assets/images/music/footer-bg.jpg)'}">

<app-accordion></app-accordion>

</section>

<app-gym-footer></app-gym-footer>
<app-gym-copyright></app-gym-copyright>
