import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-nutrizione',
  templateUrl: './nutrizione.component.html',
  styleUrls: ['./nutrizione.component.scss']
})
export class NutrizioneComponent {

  headercarousel = [
    { 
      tagLine1:"build your",
      tagLine2:"Self",
      description:"Inizia oggi, inizia da te stesso."    
    }
  ]
  
  headercarouselOptions= {
    items: 1,
    margin: 0,
    autoHeight: true,
    nav: false,
    autoplay: false,
    slideSpeed: 300,
    paginationSpeed: 400,
    loop: true,
  }

  schedulecarousel = [
    { 
      tagLine1:"#1 Step",
      tagLine2:"Valutare",
      description1:"valutare i fabbisogni nutrizionali individuali con opportune metodiche ",
      immagine: "../../../assets/images/gym/nutrizione/1.jpg"

    },
    { 
      tagLine1:"#2 Step  ",
      tagLine2:"Confrontare",
      description1:"confrontare l'apporto effettivo con il fabbisogno teorico ",
      immagine: "../../../assets/images/gym/nutrizione/2.jpg"
    },

    { 
      tagLine1:"#3 Individuare  ",
      tagLine2:"Individurare",
      description1:"individuare la miglior strategia d'azione, ponendo particolare attenzione alla storia passata del paziente, al suo ambiente di vita, agli ostacoli oggettivi e soggettividel cambiamento. ",
      immagine: "../../../assets/images/gym/nutrizione/nutrizione_1.jpg"
    },
 
  ]
  
  schedulecarouselOptions= {
    items: 1,
    margin: 0,
    dots: true,
    nav: false,
    autoplay: false,
    slideSpeed: 300,
    paginationSpeed: 400,
    loop: true,
  }

}
