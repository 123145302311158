import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-about-me',
  templateUrl: './about-me.component.html',
  styleUrls: ['./about-me.component.scss']
})
export class AboutMeComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

  timeline= [
    { caption: '16 Jan', date: new Date(2015, 1, 16),  title: 'Laurea scienze motorie e sportive',
    content: 'Dopo il liceo, ho intrapreso il mio percorso di studi presso la facoltà di Scienze Motorie e tecnica sportiva dell’università di pavia, laureandomi nel 2015 con tesi intitolata “Metodologie e preparazione nel Natural BodyBuilding.',
  image:'assets/images/timeline/laurea pavia.jpg' },
    { caption: '28 Feb', date: new Date(2016, 2, 28),  title: 'Diploma SNPT',
    content: 'Mentre terminavo gli studi, ho deciso di approfondire l’ambito del fitness e della preparazione sportiva, diplomandoti Personal Trainer CSI/SNPT presso la Scuola Nazionale Personal Traine',
    image:'assets/images/timeline/snpt.png' },
    { caption: '20 Mar', date: new Date(2017, 3, 20), selected: true,  title: 'Laurea in Biologia Applicata alle Scienze della Nutrizone',
    content: 'Dopo essermi inserito lavorativamente nel mondo delle palestra come istruttore di sala, ho deciso di approcciarmi ad una seconda laurea in Nutrizione, fermamente convinto dell’imprenscindibile importanza del binomio allenamento- alimentazione. Durante l’ultimo anno di studi, ho fatto un tirocinio ospedaliero presso il reparto dietologia e nutrizione pediatrica dell’ASST Rhodense di Bollate (MI) portando in tesi “Analisi di prevalenza della Sindrome Metabolica in una popolazione di bambini sovrappeso/obesi. Studio delle correlazioni tra parametri antropometrici ed ematici e questionari anamnestici', 
    image:'assets/images/timeline/laurea.jpg'},
    { caption: '20 May', date: new Date(2018, 5, 20), title: 'SANIS',
    content: 'Data la mia passione intrinseca per lo sport, ho approfondito l’argomento Nutrizione Sportiva, conseguendo il Diploma Sanis, la massima Certificazione Nazionale per Nutrizione ed Integrazione Sportiva, portando in tesina “il ruolo dei BCAA nell’attività sportiva”',
    image:'assets/images/timeline/Sanis.jpg' },
    { caption: '09 Feb', date: new Date(2020, 2, 9),  title: 'Iscrizione all’ordine',
    content: 'Nel febbraio del 2020, mi sono iscritto all’ordine nazionale dei biologi, abilitandomi a tutti gli effetti Biologo Nutrizionista (Sez.A N.088447).', 
    image:'assets/images/timeline/_DSC3011.jpg'},
    { caption: '30 Sett', date: new Date(2020, 9, 30),  title: 'Docente SNPT',
    content: 'Nel Settembre 2020, entro a far parte del corpo docenti SNPT , formando i futuri trainer le competenze necessarie per essere dei professionisti del settore allenamento..',
    image:'assets/images/timeline/snpt.jpg' },
    // { caption: '15 Sep', date: new Date(2014, 9, 15), title: 'Status#6', content: 'this.content' },
    // { caption: '01 Nov', date: new Date(2014, 11, 1), title: 'Status#7', content: 'this.content' },
    // { caption: '10 Dec', date: new Date(2014, 12, 10), title: 'Status#8', content: 'this.content' },
    // { caption: '29 Jan', date: new Date(2015, 1, 19), title: 'Status#9', content: 'this.content' },
    // { caption: '3 Mar', date: new Date(2015, 3, 3), title: 'Status#9', content: 'this.content' },
    ];;


events = [
    {
      id: 0,
      title: 'Laurea scienze motorie e sportive',
      content: 'Dopo il liceo, ho intrapreso il mio percorso di studi presso la facoltà di Scienze Motorie e tecnica sportiva dell’università di pavia, laureandomi nel 2015 con tesi intitolata “Metodologie e preparazione nel Natural BodyBuilding.',
      date: '2015',
      icon: 'assets/logo/LogoEdo.png'
    },
    {
      id: 1,
      title: 'Diploma SNPT',
      content: 'Mentre terminavo gli studi, ho deciso di approfondire l’ambito del fitness e della preparazione sportiva, diplomandoti Personal Trainer CSI/SNPT presso la Scuola Nazionale Personal Traine',
      date: '2015 - 2016',
      icon: ''
    },
    {
      id: 2,
      title: 'Laurea in Biologia Applicata alle Scienze della Nutrizone',
      content: 'Dopo essermi inserito lavorativamente nel mondo delle palestra come istruttore di sala, ho deciso di approcciarmi ad una seconda laurea in Nutrizione, fermamente convinto dell’imprenscindibile importanza del binomio allenamento- alimentazione. Durante l’ultimo anno di studi, ho fatto un tirocinio ospedaliero presso il reparto dietologia e nutrizione pediatrica dell’ASST Rhodense di Bollate (MI) portando in tesi “Analisi di prevalenza della Sindrome Metabolica in una popolazione di bambini sovrappeso/obesi. Studio delle correlazioni tra parametri antropometrici ed ematici e questionari anamnestici',
      date: '2017',
      icon: ''
    },
    {
      id: 3,
      title: 'SANIS',
      content: 'Data la mia passione intrinseca per lo sport, ho approfondito l’argomento Nutrizione Sportiva, conseguendo il Diploma Sanis, la massima Certificazione Nazionale per Nutrizione ed Integrazione Sportiva, portando in tesina “il ruolo dei BCAA nell’attività sportiva”',
      date: '2018',
      icon: ''
    },
    {
      id: 4,
      title: 'Iscrizione all’ordine',
      content: 'Nel febbraio del 2020, mi sono iscritto all’ordine nazionale dei biologi, abilitandomi a tutti gli effetti Biologo Nutrizionista (Sez.A N.088447).',
      date: '2020',
      icon: ''
    },
    {
      id: 5,
      title: 'Docente SNPT',
      content: 'Nel Settembre 2020, entro a far parte del corpo docenti SNPT , formando i futuri trainer le competenze necessarie per essere dei professionisti del settore allenamento..',
      date: '2020',
      icon: ''
    }
  ];


  headercarousel = [
    { 
      tagLine1:"build your",
      tagLine2:"Self",
      description:"Inizia oggi, inizia da te stesso."    
    }
    // { 
    //   tagLine1:"build your",
    //   tagLine2:"body more strong",
    //   description:"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took."    
    // },  
  ]
  
  headercarouselOptions= {
    items: 1,
    margin: 0,
    autoHeight: true,
    nav: false,
    autoplay: false,
    slideSpeed: 300,
    paginationSpeed: 400,
    loop: true,
  }


  schedulecarousel = [
    { 
      tagLine1:"#1 Step",
      tagLine2:"Valutare",
      description1:"valutare i fabbisogni nutrizionali individuali con opportune metodiche ",
    },
    { 
      tagLine1:"#2 Step  ",
      tagLine2:"Confrontare",
      description1:"confrontare l'apporto effettivo con il fabbisogno teorico ",
    },

    { 
      tagLine1:"#3 Individuare  ",
      tagLine2:"Individurare",
      description1:"individuare la miglior strategia d'azione, ponendo particolare attenzione alla storia passata del paziente, al suo ambiente di vita, agli ostacoli oggettivi e soggettividel cambiamento. ",
    },
 
  ]
  
  schedulecarouselOptions= {
    items: 1,
    margin: 0,
    dots: true,
    nav: false,
    autoplay: false,
    slideSpeed: 300,
    paginationSpeed: 400,
    loop: true,
  }

}
