import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-allenamento',
  templateUrl: './allenamento.component.html',
  styleUrls: ['./allenamento.component.scss']
})
export class AllenamentoComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

  headercarousel = [
    { 
      tagLine1:"build your",
      tagLine2:"Self",
      description:"Inizia oggi, inizia da te stesso."    
    },
    { 
      tagLine1:"build your",
      tagLine2:"body more strong",
      description:"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took."    
    },  
  ]
  
  headercarouselOptions= {
    items: 1,
    margin: 0,
    autoHeight: true,
    nav: false,
    autoplay: false,
    slideSpeed: 300,
    paginationSpeed: 400,
    loop: true,
  }


  schedulecarousel = [
    { 
      tagLine1:"Scheda di allenamento",
      tagLine2:"come funziona?",
      description1:"All'interno della dettagliata anamnesi iniziale, verrà considerata anche la parte di allenamento. L'allenamento è una scienza, va programmato in base alle tue esigenze e alla tua soggettività. Quando ci troviamo ad iniziare un nuovo percorso assieme, sarà importante non solo stilare una semplice lista di esercizi da effettuare, ma anche raccogliere una serie di dati personali per poter adattare e personalizzare il programma sia agli obiettivi che alla condizione fisica. Ne consegue che, prima di impostare qualsiasi allenamento, verranno eseguiti dei test di valutazione/efficienza corporea (che variano da soggetto a soggetto).Questo mi permetterà di: ",
      description2:"- Individuare eventuali problematiche di mobilità/stabilità",
      description3:"- Correggere l'esecuzione tecnica degli esercizi",
      description4:"- Riprodurre assieme gli esercizi che verranno assegnati nel programma",
      description5:"- Stabilire assieme gli obbiettivi in termini di allenamento",

    } 
  ]
  

  // <!-- All'interno della dettagliata anamnesi iniziale, verrà considerata anche la parte di allenamento.
  // L'allenamento è una scienza, va programmato in base alle tue esigenze e alla
  // tua soggettività. Quando ci troviamo ad iniziare un nuovo percorso assieme, sarà importante
  // non solo stilare una semplice lista di esercizi da effettuare, ma anche raccogliere una serie di dati
  // personali per poter adattare e personalizzare il programma sia agli obiettivi che alla condizione
  // fisica. Ne consegue che, prima di impostare qualsiasi allenamento, verranno eseguiti dei test di
  // valutazione/efficienza corporea (che variano da soggetto a soggetto).
  // Questo mi permetterà di:
  // - Individuare eventuali problematiche di mobilità/stabilità
  // - Correggere l'esecuzione tecnica degli esercizi
  // - Riprodurre assieme gli esercizi che verranno assegnati nel programma
  // - Stabilire assieme gli obbiettivi in termini di allenamento.
  
  // Dopo aver raccolto questi dati, potrò preparare il programma personalizzato ed iniziare ad
  // assisterti nel tuo. percorso allenante.
  
  // Durata totale dell'incontro è di circa 45/60 minuti. Inoltre, per chi comincia il percorso di
  // coaching (LINK A PACCHETTI), si aggiungeranno i dati dell'anamnesi alimentare (LINK A
  // NUTRIZIONE) -->


  schedulecarouselOptions= {
    items: 1,
    margin: 0,
    dots: true,
    nav: false,
    autoplay: false,
    slideSpeed: 300,
    paginationSpeed: 400,
    loop: true,
  }

}
