import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { VideoService } from './video.service';

@Component({
  selector: 'app-video-corsi',
  templateUrl: './video-corsi.component.html',
  styleUrls: ['./video-corsi.component.scss']
})
export class VideoCorsiComponent {
  @Input() selectedVideo: any;

  video: any; // 12

  constructor(private route: ActivatedRoute, private videoService: VideoService) { }

  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
      const videoId = +params.get('id');
      this.video = this.videoService.getVideoById(videoId);
    });
  }

}
