<section class="" style="background-color: grey;" id="home"></section>
<div class="agency">
    <!-- breadcrumb section start -->
    <section class="breadcrumb-section-main inner-2 breadcrumb-section-sm">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="breadcrumb-contain ">
                        <div>
                            <h2 class="breadcrumb-txt">shop with cart</h2>
                        </div>
                        <div>
                            <ul>
                                
                                <li><a href="#">shop pages</a></li>
                                <li><a href="#"><i class="fa fa-angle-double-right"></i>cart</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!--breadcrumb section end -->
    
    <!-- section start -->
    <section class="cart-section section-b-space">
        <div class="container">
          <div class="col-sm-12 empty-cart-cls text-center" *ngIf="!shoppingCartItems.length">
            <img src="assets/images/icon-empty-cart.png" class="img-fluid mb-4">
            <h3><strong>Your Cart is Empty</strong></h3>
            <h4>Add something to make me happy :)</h4>
            <a [routerLink]="['/e-commerce']" class="btn btn-default primary-btn radius-0 m-t-10">continue shopping</a>
          </div>
          <div class="row" *ngIf='shoppingCartItems.length'>
            <div class="col-sm-12">
              <table class="table cart-table table-responsive-xs">
                <thead>
                  <tr class="table-head">
                    <th scope="col">image</th>
                    <th scope="col">product name</th>
                    <th scope="col">price</th>
                    <th scope="col">quantity</th>
                    <th scope="col">action</th>
                    <th scope="col">total</th>
                  </tr>
                </thead>
                <tbody *ngFor="let item of shoppingCartItems">
                  <tr>
                    <td>
                      <a [routerLink]="['/e-commerce/product', item.product.id]">
                      <img [src]="item.product.img" alt="">
                    </a>
                    </td>
                    <td>
                      <a [routerLink]="['/e-commerce/roduct', item.product.id]">{{ item.product.name }}</a>
                      <div class="mobile-cart-content row">
                        <div class="col-xs-3">
                          <div class="qty-box">
                            <div class="input-group">
                              <input type="text" name="quantity" class="form-control input-number" disabled [value]="item.quantity">
                            </div>
                          </div>
                        </div>
                        <div class="col-xs-3">
                          <h2 class="td-color">{{ item.product.price | currency:'EUR':true }}</h2>
                        </div>
                        <div class="col-xs-3">
                          <h2 class="td-color">
                          <a (click)="removeItem(item)" class="icon">
                            <i class="ti-close"></i>
                          </a>
                        </h2>
                        </div>
                      </div>
                    </td>
                    <td><h2>{{ item.product.price | currency:'EUR':true }}</h2>
                    </td>
                    <td>
                      <div class="qty-box">
                        <div class="input-group">
                          <span class="input-group-prepend">
                          <button type="button" class="btn quantity-left-minus" (click)="decrement(item.product)" data-type="minus" data-field="">
                            <i class="fa fa-chevron-left"></i>
                          </button>
                        </span>
                          <input type="text" name="quantity" disabled class="form-control input-number" [(ngModel)]="item.quantity">
                          <span class="input-group-prepend">
                          <button type="button" class="btn quantity-right-plus" (click)="increment(item.product)" data-type="plus" data-field="">
                            <i class="fa fa-chevron-right"></i>
                          </button>
                        </span>
                        </div>
                      </div>
                    </td>
                    <td>
                      <a [routerLink]="[]" (click)="removeItem(item)" class="icon">
                      <i class="fa fa-times"></i>
                    </a>
                    </td>
                    <td>
                      <h2 class="td-color">{{ item.product.price * item.quantity | currency:'EUR':true }}</h2>
                    </td>
                  </tr>
                </tbody>
              </table>
              <table class="table cart-table table-responsive-md">
                <tfoot>
                  <tr>
                    <td>total price :</td>
                    <td>
                      <h2> {{ getTotal() | async | currency:'EUR':true}}</h2>
                    </td>
                  </tr>
                </tfoot>
              </table>
            </div>
          </div>
          <div class="row cart-buttons" *ngIf='shoppingCartItems.length'>
            <div class="col-6">
              <a [routerLink]="['/landingpage']" class="btn btn-default primary-btn radius-0 m-t-10">continue shopping</a>
            </div>
            <div class="col-6">
              <a [routerLink]="['/checkout']" class="btn btn-default primary-btn ra-dius-0 m-t-10">check out</a>
            </div>
          </div>
        </div>
    </section>
    <!--section end-->
</div>