<!-- about section Start-->
<section class="gym format" id="about">
    <div class="container">
        <div class="row">
            <div class="col-md-6">
                <div class="text-center center-content">
                    <div class="about-img">
                        <div class="borders-around">
                            <div>
                                <div class="bg-black"></div>
                            </div>
                            <img src="assets/images/gym/_DSC4614.jpg" alt="" class="img-fluid format-img">
                        </div>
                    </div> 
                </div>
            </div>
            <div class="col-md-6">
                <div class="center-text">
                    <div>
                        <div class="format-small-text">
                            <h6 class="font-primary borders-before"><span>Edoardo Dall' Olio</span></h6>
                        </div>
                        <div class="format-head-text">
                            <h3 class="about-font-header gradient-text" style="color:#ec5c15">Allenamento</h3>
                        </div>
                        <div class="format-sub-text">
                            <p class="p-light about-para">Ciao! Mi presento, sono un Biologo Nutrizionista e Coach. 

                                Mi occupo di allenamento, nutrizione ed integrazione per chiunque sia seriamente intenzionato a migliorare la propria salute ed il proprio corpo.
                                Cambiare il proprio stile di vita si può, a partire da subito e per sempre..</p>
                            <!-- <p class="p-light about-para">When an unknown printer took a galley of type and scrambled it
                                to make
                                a type specimen
                                book. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem
                                Ipsum has been the industry.</p> -->
                        </div>
                        <a href="/nutrizione" class="btn btn-default primary-btn">Scopri di più</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- about section end-->
