import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators  } from '@angular/forms';
import { UserService } from './../../services/user.service';
import { LogBody } from './../../models/user';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  submitted = false;
  error= '';
  Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 2000,
    timerProgressBar: true
  })
  constructor(private formBuilder: FormBuilder, private readonly userService: UserService, private router: Router) {
    this.loginForm = this.formBuilder.group({
      email: ['', Validators.required],
      password: ['', Validators.required]
    });
  }

  ngOnInit() {
  }

  onSubmit() {
    this.submitted = true;
    if (this.loginForm.valid) {
      const data: LogBody = {
        email: this.loginForm.value.email,
        password: this.loginForm.value.password
      };
      this.error= '';
      Swal.fire({
        title: 'Accesso in corso...',
        didOpen: () => {
          Swal.showLoading()
        },
      })
      this.userService.login(data).subscribe(
        (response) => {
          Swal.close();
          localStorage.setItem('token', response.access);
          localStorage.setItem('refresh', response.refresh);
          this.Toast.fire({
            icon: 'success',
            title: 'Signed in successfully'
          }).then(() => {
            this.router.navigate(['/corsi']);
          })
        },
        (error) => {
          Swal.close();
          this.Toast.fire({
            icon: 'error',
            title: 'Ops'
          });
          this.error = error?.error?.detail;
          console.error(error);
        }
      );
    }
  }

}
