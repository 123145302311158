import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { CommonModule } from '@angular/common';


@Component({
  selector: 'app-conferma-attivazione',
  templateUrl: './conferma-attivazione.component.html'
})
export class ConfermaAttivazioneComponent implements OnInit {

  token: string | null= "";
  uid: string | null= "";


  constructor(private readonly route: ActivatedRoute, private readonly http: HttpClient) { }

  ngOnInit(): void {
    console.log("init")
    this.token = this.route.snapshot.paramMap.get('token');
    this.uid = this.route.snapshot.paramMap.get('uid');

  }

  confermaAttivazione(): void {
    const url = 'https://pcprecisioncoaching.it/api/auth/users/activation/';
    const body = {
      uid: this.uid,
      token: this.token
    };

    this.http.post(url, body).subscribe({
      next: (response: any) => console.log('Attivazione confermata', response),
      error: (error: any) => console.error('Errore durante l\'attivazione', error)
    });
  }

}
