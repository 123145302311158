<!--testimonial section start-->
<section class="gym testimonial" [ngStyle]="{'background-image': 'url(assets/images/gym/testimonial/testimonial-bg.jpg)'}">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class="title">
                    <div class="text-center">
                        <div>
                            <h6 class=" borders-before text-white"><span>Testimonianze</span></h6>
                        </div>
                        <div class="main-title">
                            <h2 class="text-white">Cosa dicono di Noi </h2>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-8 offset-lg-2">
                <owl-carousel-o [options]="testimonialCarouselOptions" class=" testimonial-slider">
                    <ng-container *ngFor="let user of users">
                        <ng-template carouselSlide class="item">
                            <div class="">
                                <img [src]="user.img" alt="" class="img-fluid m-auto">
                                <!-- <div class="text-center testimonial-info">
                                    <p class="p-light testimonial-para">Edoardo mi segue sia nell’ allenamento che nell’alimentazione, e dopo i primi mesi ho già visto i risultati! Gli esercizi e la dieta sono ben personalizzati in base ad abitudini e stile di vita, e ovviamente al tipo di esercizio richiesto. Il bello è la capacità di farti vivere il tutto non come un sacrificio ma come stile di vita più sano. sempre disponibile per problemi e chiarimenti, decisamente consigliato!.</p>
                                    <div class="testimonial-name">
                                        <h5 class="text-white name">Anna Papeo
                                        </h5>
                                    </div>
                                    <div class="testimonial-post">
                                    </div>
                                </div> -->
                                <div class="text-center testimonial-info">
                                    <p class="p-light testimonial-para">{{user.body}}
                                        </p>
                                    <div class="testimonial-name">
                                        <h5 class="text-white name">{{user.name}}
                                        </h5>
                                    </div>
                                    <div class="testimonial-post">
                                        
                                        <p class="p-light" style="color:#ec5c15"><a [href]="googleReviewsUrl">Leggi le recensioni su Google</a></p>

                                        <!-- <h6 class="text-white post">CEO OF SC.</h6> -->
                                    </div>
                                </div>
                            </div>
                        </ng-template>
                    </ng-container>
                </owl-carousel-o>
            </div>
        </div>
    </div>
</section>
<!--testimonial section end-->