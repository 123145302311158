<!-- <section style="background-color: #ec5c15;"> -->
  <header class="gym loading-header nav-abs custom-scroll" style="background-color: black;">
      <div class="container">
          <div class="row">
             <div class="col">
                  <nav>
                      <a href="#" class="d-inline-block m-r-auto">
                          <img src="assets/images/logo/123 copia.png" alt="Logo" style="max-width: 150px; min-width: auto;" class="img-fluid">
                      </a>
    
                      <app-menu></app-menu>
                  
                  </nav>
              </div>
          </div>
      </div>
  </header>
<!-- </section> -->

<section  class="gym format" >
  
  <button class="navbar-toggler d-md-none" type="button" (click)="isMenuCollapsed = !isMenuCollapsed"
aria-expanded="false" aria-label="Toggle navigation" style="color: white; z-index: 1050; left: 15px;">
<span style="color:#ec5c15">        <i class="fa fa-bars" aria-hidden="true"></i>
  Video</span> <!-- Aggiornato per coerenza e comprensione -->
</button>
  <div class="container-fluid">
      <div class="row">
          <!-- Overlay per chiudere il menu cliccando fuori, visibile solo su dispositivi mobili -->
          <div *ngIf="!isMenuCollapsed" class="menu-overlay" (click)="isMenuCollapsed = true"></div>

          <!-- Menu a sinistra -->
          <div [ngClass]="{'menu-mobile': true, 'show': !isMenuCollapsed}" class="col-md-3">
              <input [(ngModel)]="searchText" type="text" class="form-control d-none d-md-block mt-2" placeholder="Cerca video...">
              <div *ngIf="corsi.length > 0" class="mt-2">
                <ngb-accordion *ngFor="let corso of corsi; let i = index" #acc="ngbAccordion" activeIds="panel1" [closeOthers]="true">
                    <ng-container  *ngIf="corso?.gruppo !=='Base'">
                  <ngb-panel  id="panel{{i}}">
                    <ng-template ngbPanelTitle>
                      <span>{{corso.gruppo}}</span>
                    </ng-template>
                    <ng-template ngbPanelContent>
                      <ul class="list-group">
                        <li *ngFor="let video of corso.video | filterVideo:searchText" class="list-group-item" (click)="onVideoSelect(video)">
                          <i class="bi bi-play-circle-fill"></i> {{ video.name }} ({{ handleTime(video.duration) }})
                        </li>
                      </ul>
                    </ng-template>
                  </ngb-panel>
                </ng-container>

                </ngb-accordion>
              </div>
              
          </div>

          <!-- Contenuto del video a destra -->
          <div class="col-md-9">
              <div *ngIf="videoDaVisualizzare; else nada">
                  <div class="format-head-text">
                    <h3 class="about-font-header gradient-text mt-4" style="color:#ec5c15">{{ title }}</h3>
                </div>
                  <div style="padding-bottom:56.25%; position:relative; display:block; width: 100%; margin-top: 30px;">
                      <iframe width="100%" height="100%" [src]="sanitizer.bypassSecurityTrustResourceUrl(videoDaVisualizzare)"
                              frameborder="0" allowfullscreen style="position:absolute; top:0; left: 0">
                      </iframe>
                  </div>
                  <!-- <p class="mt-5">{{description}}</p> -->
                  <div class="format-sub-text mt-5">
                    <p class="p-light about-para">{{description}}
                </div>
              </div>
          </div>
      </div>
  </div>

  <ng-template #nada>
      <div style="text-align:center; padding:20px;">
          Nessun video selezionato
      </div>
  </ng-template>
</section>
<app-gym-footer></app-gym-footer>
<app-gym-copyright></app-gym-copyright>
