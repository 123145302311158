<!-- about section Start-->
<section class="gym format">
    <div class="container">
        <div class="row">
            <div class="col-md-6">
                <div class="text-center center-content">
                    <div class="about-img">
                        <div class="borders-around">
                            <div>
                                <div class="bg-black"></div>
                            </div>
                            <img src="assets/images/gym/about EDO.jpg" alt="" class="img-fluid format-img">
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div class=" center-text">
                    <div>
                        <div class="format-small-text">
                            <h6 class="font-primary borders-before"><span>Edoardo Dall' Olio</span></h6>
                        </div>
                        <div class="format-head-text">
                            <h3 class="about-font-header gradient-text" style="color:#ec5c15">Nutrizione</h3>
                        </div>
                        <div class="format-sub-text">
                            <div class="sub-heading">
                                <h4>Comincia da te</h4>
                            </div>
                            <!-- <p class="p-light about-para"> Inizia da te parti adesso e non aspettare domani.Compila l'anamnesi vai al link <a href="https://www.precisioncoaching.info/">Click qui</a></p>
                            <div class="sub-heading">
                                <h4>2. Posture Correction</h4>
                            </div> -->
                           <p class="p-light about-para">Dieta, dal greco dìaita = regime, stile, tenore di vita, sono qui per guidarvi in ogni passo del vostro percorso alimentare.
                          </p>
                          <p class="p-light about-para"> La corretta alimentazione può trasformare la vostra vita in maniera sana ed equilibrata.</p>

                        </div>
                        <a href="/nutrizione" class="btn btn-default primary-btn">Scopri di Più</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- about section end-->
