<app-gym-nav></app-gym-nav>

<section class="gym breadcrumb-section video-sec breadcrumb-slider p-0" style="height: 100vh" id="home">
    <video playsinline="" muted="" onplaying="this.controls=false" autoplay loop>
        <source src="assets/video/TrailerEDo.mov" type='video/mp4;'>
        <source src="assets/video/TrailerEDo.mov" type='video/webm;'>
        <source src="assets/video/TrailerEDo.mov" type='video/ogg;'>
    </video>
<div class="container set-over">
    <div class="row">
        <div class="col-12">
            <ul class="breadcrumb justify-content-center">
            </ul>
        </div>
    </div>
</div>
</section>

<section class="gym format" id="about">
<div class="landing-page">
    <div class="hero-section">
      <h1 class="main-heading">Il Fitness a un nuovo livello</h1>
      <div class="format-head-text">
        <h3 class="about-font-header gradient-text" style="color:#ec5c15">Ti sei mai chiesto qual’è l’ago della bilancia che distingue un allenamento efficiente da uno non efficiente?</h3>
    </div>
      <div class="container d-flex align-items-center justify-content-center">
        <div class="text-center sub-heading">
          <p><strong>NON E’ IL NUMERO DI SERIE</strong></p>
          <p><strong>NON E’ IL RANGE DI RIPETIZIONI</strong></p>
          <p><strong>NON SONO I CARICHI SUL BILANCIERE</strong></p>
          <p><strong>NON E’ IL TEMPO TRASCORSO IN PALESTRA</strong></p>
          <p><strong>...E NON E’ NEANCHE IL MACCHINARIO DI ULTIMA GENERAZIONE DA 12K</strong></p>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="gym format bg-schedule" id="schedule"> 
    <div class="container">
        <div class="row">
            <div class="col-md-10 offset-md-1">
                <div class="title">
                    <div class="main-title">
                        <!-- <h2 class="text-white m-b-15">Nutrizione</h2> -->
                    </div>
                    <img src="assets/images/logo/l5-title-bottom.png" alt="" class="img-fluid">
                </div>
            </div>
            <div class="container-fluid">
                <div class="row">
                  <!-- Colonna per l'immagine -->
                  <div class="col-md-6">
                    <img src="assets/images/PCTEST-removebg-preview.png" class="img-fluid" alt="Descrizione immagine">
                  </div>
              
                  <!-- Colonna per il testo e il carosello -->
                  <div class="col-md-6">
                    <div class="col-12">
                      <owl-carousel-o [options]="schedulecarouselOptions" class="default-dots gym-about-slider">
                        <ng-container *ngFor="let schedule of schedulecarousel">
                          <ng-template carouselSlide>
                            <div class="item">
                              <div class="center-text">
                                <div class="format-small-text">
                                  <h6 class="text-white borders-before"><span>{{schedule.tagLine1}}</span></h6>
                                </div>
                                <div class="format-head-text">
                                  <h3 class="text-white about-font-header">{{schedule.tagLine2}}</h3>
                                </div>
                                <div class="format-sub-text">
                                  <p class="p-light about-para text-white">{{schedule.description1}}</p>
                                  <p class="p-light about-para text-white">{{schedule.description2}}</p>
                                  <ul>
                                    <li class="p-light about-para text-white"><strong>✅ Istruzioni Chiare:</strong> I miei tutorial sono progettati per essere facili da seguire, anche per i principianti...</li>
                                    <li class="p-light about-para text-white"><strong>✅ Affidabilità:</strong> Lascia che mi presenti brevemente. Sono Dott. In Scienze Motorie, Personal Trainer certificato e dal 2020 docente presso la Scuola Nazionale Personal Trainer. Ho +10 di esperienza in sala pesi con oltre un centinaio di persone allenate (atleti e principianti), oltre che la mia personale esperienza da autodidatta e come atleta. Se vuoi sapere di più sul mio percorso clicca qui </li>
                                    <li class="p-light about-para text-white"><strong>✅ Accesso Illimitato:</strong> Una volta che hai accesso ai miei tutorial tramite il percorso coaching, sono tuoi per sempre. Puoi allenarti quando e dove vuoi, senza costi mensili.</li>
                                    <li class="p-light about-para text-white"><strong>✅ Continuo Aggiornamento:</strong> Il portale è sempre in continuo aggiornamento pronto a fornirti supporto in ogni fase del tuo percorso. Viene continuamente ampliato con i vari esercizi per renderlo sempre più completo ed preciso.</li>
                                    <li class="p-light about-para text-white"><strong>✅ +80 Esercizi:</strong> I tutorials offrono una vasta gamma di esercizi: Bilancieri, manubri, corpo libero, macchinari isotonici e cavi.</li>
                                    <li class="p-light about-para text-white"><strong>✅ Percorso online completo:</strong> I tutorials verranno affiancati all’interno dei miei percorsi di coaching online. In questo modo avrai tutto il materiale che ti serve per cominciare il tuo percorso: Allenamento 🏋️​– Nutrizione 🥝​ – Integrazione ​💊​– Tutorials Esecutivi📽️</li>
                                    <li class="p-light about-para text-white"><strong>✅ Supporto continuo:</strong> Una volta cominciato il percorso, rimango a disposizione per qualsiasi chiarimento/dubbio/domanda in merito alla revisione-correzione degli esercizi. (Tramite gli orari di reperibilità di whatsapp business e per mail).</li>
                                  </ul>
                                  <br>

                                </div>
                              </div>
                            </div>
                          </ng-template>
                        </ng-container>
                      </owl-carousel-o>
                    </div>
                  </div>
                </div>

                <div class="row">
                    <div class="col">
                      <h3 class="text-white">A CHI SERVONO ✅</h3>
                      <p class="text-white">- Neofiti o principianti che partono da zero</p>
                      <p class="text-white">- Chiunque vuole rivedere o perfezionare la propria esecuzione</p>
                      <p class="text-white">- Chi desidera essere seguito a 360° in un percorso online</p>
                      <p class="text-white">- Futuri PT che si stanno formando come materiale per gli esami di formazione</p>
                    </div>
                    <div class="col">
                      <h3 class="text-white">A CHI NON SERVONO 🚫</h3>
                      <p class="text-white">- Avanzati con un bagaglio di esperienza in sala pesi</p>
                      <p class="text-white">- Soggetti già pratici delle esecuzioni degli esercizi in palestra</p>
                      <p class="text-white">- Persone che decidono di affidarsi ad altri video tutorials</p>
                      <p class="text-white">- Chi ha a disposizione un trainer ad ogni allenamento</p>
                    </div>
                  </div>
            </div>
              
        </div>
    </div>
</section>


<section class="app1 pricing" id="plan">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class="title title2 title-inner">
                    <div class="main-title">
                        <h2 class="font-primary borders text-center main-text m-b-0 text-uppercase"><span>Cosa troverai nei miei pacchetti</span></h2>
                    </div>
                </div>
            </div>
            <div class="col-12">
                <owl-carousel-o [options]="pricingcarouselOptions1" class="pricing-slider  price-margin">
                    <ng-container *ngFor="let slide of pricing1">
                        <ng-template carouselSlide class="item">
                        <div class="price-container price-margin shadows text-center">
                            <div class="price-feature-container set-relative">
                                <div class="feature-text">
                                    <h3 class="feature-text-heading text-center font-primary">{{slide.heading}}</h3>
                                    <hr/>
                                </div>
                                <div class="price-features">
                                    <h5 class="price-feature text-center">{{slide.features1}}</h5>
                                    <h5 class="price-feature text-center">{{slide.features2}}</h5>
                                    <h5 class="price-feature text-center">{{slide.features3}}</h5>
                                    <h5 class="price-feature text-center">{{slide.features4}}</h5>
                                    <h5 class="price-feature text-center">{{slide.features5}}</h5>
                                    <h5 class="price-feature text-center">{{slide.features6}}</h5>
                                    <h5 class="price-feature text-center">{{slide.features7}}</h5>
                                </div>
                                    <a [href]="'https://www.precisioncoaching.info/'"><button class=" btn btn-default btn-gradient text-white btn-app1-theme" (click)="goToCart(slide)">{{slide.btn}}</button></a>
                            </div>
                        </div>
                      </ng-template>
                      </ng-container>
                    </owl-carousel-o> 

            </div>


        </div>

        <div class="row">
            <div class="col align-self-start">
              </div>
    <div class="col align-self-center">           
        <a href="https://www.precisioncoaching.info/" class="btn btn-default primary-btn">
            Contattami per iniziare il percorso personalizzato
        </a>

        <!-- <a href="https://www.precisioncoaching.info/" class="cta-button">Contattami per iniziare il percorso personalizzato</a> -->
    </div>
    <div class="col align-self-end">
      </div>
  </div>
    </div>
</section>

<app-gym-footer></app-gym-footer>
<app-gym-copyright></app-gym-copyright>
