import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';

import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';


@Component({
  selector: 'app-gym-testimonial',
  templateUrl: './gym-testimonial.component.html',
  styleUrls: ['./gym-testimonial.component.scss']
})
export class GymTestimonialComponent implements OnInit {



  reviews: any[];

  getGoogleReviews() {
    const placeId = '0x4bdffe718e24a2f5:0x7d030e739cf766de';
    const apiKey = 'AIzaSyBLivkyarx-qfM-j6MxIxZtcuJ-BefD5oM';
    const url = `https://maps.googleapis.com/maps/api/place/details/json?place_id=${placeId}&key=${apiKey}`;

    this.http.get(url)
      .subscribe((data: any) => {
        this.reviews = data.result.reviews;
        console.log(this.reviews)
      });
  }
  constructor(private sanitizer: DomSanitizer, private http: HttpClient) { }
  public googleReviewsUrl: SafeResourceUrl;

  ngOnInit() {
    const googleReviewsUrl = 'https://www.google.com/search?q=PC+PrecisionCoaching&stick=H4sIAAAAAAAA_-NgU1I1qDBJSklLSzU3tEg1Mkk0SjO1MqgwTzEwNkg1N7ZMTjM3M0tJXcQqEuCsEFCUmpxZnJmf55yfmJyRmZcOAF_BGa1AAAAA&hl=it&mat=CWiKsiTeXM2OElcBpsFAC0KPZdl7VauPz6gi0TCbjL2hlaKH3PUegcF73nWJmHfkwwf9u8Hpgs33EREw7uIm9bff36pQfdKGuY3RWOHisJPFKlMhwg4FySK5iPbhqfrY6g8&authuser=0#lrd=0x4bdffe718e24a2f5:0x7d030e739cf766de,1,,,,';
this.googleReviewsUrl = this.sanitizer.bypassSecurityTrustResourceUrl(googleReviewsUrl);
this.getGoogleReviews();

  }


  users = [
    { 
      img:"assets/images/gym/testimonial/quote.png",
      body:"Edoardo è un coach super professionale!In un anno di allenamenti e alimentazione ha completamente stravolto il mio corpo in meglio! Sempre disponibile, preparato e soprattutto in grado di ascoltare le esigenze di chi segue! Ora con lui al fianco spero di salire sul palco per le gare di natural bodybuilding! Super super consigliato il mitico Dr. Dall'olio! 🤩",
name:"Michela Pattini"
    },
    {
      img:"assets/images/gym/testimonial/quote.png",
      body:"Sono molto contenta e soddisfatta dal percorso intrapreso con Edo circa 6 mesi fa. Non capita spesso di trovare un personal trainer qualificato e che soprattutto ascolti la persona che ha di fronte. Quando ci si allena divertendosi si ha ancora più voglia di raggiungere gli obiettivi prefissati.",
      name:"Valentina Manca"
    },
    {
      img:"assets/images/gym/testimonial/quote.png",
      body:"Con Edoardo mi trovo benissimo !super preparato e serio nel lavoro che svolge .Non smetterò mai di ringraziarti per il fisico bomba che già inizia ad uscire ☺️",
      name:"BUKURU PEA"
    },
    {
      img:"assets/images/gym/testimonial/quote.png",
      body:"Un vero professionista, sempre disponibile è molto attento.Un vero coach",
      name:"Gaetano Cisterna"
    },
    {
      img:"assets/images/gym/testimonial/quote.png",
      body:"Scrivo in veste di collega e, a suo tempo, allievo di Edoardo.Oggi giorno risulta difficile trovare un professionista così trasparente e appassionato, grato di aver iniziato il mio percorso professionale al fianco di Edoardo.",
      name:"federico molinari"
    }
  ]
  
  testimonialCarouselOptions= {
    items: 1,
    margin: 0,
    dots: true,
    nav: false,
    autoplay: false,
    slideSpeed: 300,
    paginationSpeed: 400,
    loop: true
  }



}
