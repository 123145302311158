import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements OnInit {

  isLoading: boolean = true;

  ngOnInit(): void {
    // Simula il completamento del caricamento dopo 3 secondi
    setTimeout(() => {
      this.isLoading = false;
    }, 1500);
  }
}
