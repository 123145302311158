<!--footer start-->
<footer class="gym footer2">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-md-3 col-sm-12 set-first">
                <div class="logo-sec">
                    <div class="footer-title mobile-title">
                        <h3 class="text-white">Pc Precision Coaching</h3>
                    </div>
                    <div class="footer-contant">
                        <img src="assets/images/logo/123 copia.png" alt="" style="max-width:150px;" class="img-fluid footer-logo">
                        <div class="footer-para">
                            <h6 class="text-white para-address">PC Precision Coaching</h6>
                            <h6 class="text-white para-address">onb sez 088447</h6>
                            <h6 class="text-white para-address">P.iva 01818200337</h6>                                                      
                            <h6 class="text-white para-address">Francis Lab, Via Malta 4E, 25127,Brescia</h6>
                            <h6 class="text-white para-address">Brescia, Bs</h6>
                        </div>
                        <ul class="d-flex footer-social social">
                            <li class="footer-social-list">
                                <a href="https://www.facebook.com/pcprecisioncoaching"><i class="fa fa-facebook" aria-hidden="true"></i></a>
                            </li>
                            <li class="footer-social-list">
                                <a href="https://www.instagram.com/pc_precisioncoaching/"><i class="fa fa-instagram" aria-hidden="true"></i></a>
                            </li>
                            <!-- <li class="footer-social-list">
                                <a href="#"><i class="fa fa-google" aria-hidden="true"></i></a>
                            </li> -->
                        </ul>
                    </div>
                </div>
            </div>
            <!-- <div class="col-lg-2 col-md-3 col-sm-12">
                <div class="footer-title mobile-title">
                    <h3 class="text-white">services</h3>
                </div>
                <div class="footer-contant">
                    <h5 class="footer-headings">services</h5>
                    <div>
                        <ul class="footer-lists op-text">
                            <li class="">
                                <a href="#">Portfolio Website</a>
                            </li>
                            <li class="">
                                <a href="#">Set up an account</a>
                            </li>
                            <li class="">
                                <a href="#">Invoice Creation</a>
                            </li>
                            <li class="">
                                <a href="#">Get Our Specials</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div> -->
            <!-- <div class="col-lg-2 col-md-3 col-sm-12">
                <div class="footer-title mobile-title">
                    <h3 class="text-white">brand</h3>
                </div>
                <div class="footer-contant">
                    <h5 class="footer-headings">brand</h5>
                    <div>
                        <ul class="footer-lists op-text">
                            <li class="">
                                <a href="#">Chat24</a>
                            </li>
                            <li class="">
                                <a href="#">Zoom</a>
                            </li>
                            <li class="">
                                <a href="#">Alpha Payment</a>
                            </li>
                            <li class="">
                                <a href="#">Upwork</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div> -->
            <div class="col-lg-2 col-md-3 col-sm-12">
                <div class="footer-title mobile-title">
                    <h3 class="text-white">Menu</h3>
                </div>
                <div class="footer-contant">
                    <h5 class="footer-headings">Menu</h5>
                    <div>
                        <ul class="footer-lists op-text">
                            <li class="">
                                <a [routerLink]="['/allenamento']">Allenameno</a>
                            </li>
                            <li class="">
                                <a [routerLink]="['/nutrizione']">Nutrizione</a>
                            </li>
                            <li class="">
                                <a [routerLink]="['/about-me']">Chi Sono</a>
                            </li>
                            <li class="">
                                <a [routerLink]="['/contact']">Contatti</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <!-- <div class="col-lg-3 col-md-12 col-sm-12 set-last">
                <div class="footer-title mobile-title">
                    <h3 class="text-white">Post Tags</h3>
                </div>
                <div class="footer-contant">
                    <h5 class="footer-headings">Post Tags</h5>
                    <div class="link-btns">
                        <ul>
                            <li class="buttons">
                                <a href="#">app</a>
                            </li>
                            <li class="buttons">
                                <a href="#">business</a>
                            </li>
                            <li class="buttons">
                                <a href="#">corporation</a>
                            </li>
                            <li class="buttons">
                                <a href="#">creative</a>
                            </li>
                            <li class="buttons">
                                <a href="#">design</a>
                            </li>
                            <li class="buttons">
                                <a href="#">fashion</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div> -->

        </div>
    </div>
</footer>
<!--footer end-->
<app-tap-to-top></app-tap-to-top>
